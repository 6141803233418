import React, { useEffect, useState } from 'react';
import Button from 'components/uiKit/buttons';
import Grid from 'components/uiKit/grid';
import LoginTranslationForm from './components/login';
import TranslationForm from './components/form';
export interface TranslationItemI {
  id?: number;
  key: string;
  value?: string;
  locale?: string;
  client?: string;
  group: string;
  isHtml?: boolean;
  paths?: string[];
}
interface Props {
  currentTranslation?: TranslationItemI;
}
export const translationTokenName = 'authTranslateToken';
export default function TranslationsEnv({
  currentTranslation
}: Props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    const token = sessionStorage.getItem(translationTokenName);
    if (token) {
      setIsAuthenticated(!!token);
    }
  }, []);
  if (!isAuthenticated) {
    return <LoginTranslationForm onLogin={() => setIsAuthenticated(true)} />;
  }
  return <Grid padding="p-3" data-sentry-element="Grid" data-sentry-component="TranslationsEnv" data-sentry-source-file="index.tsx">
            <TranslationForm currentTranslation={currentTranslation} data-sentry-element="TranslationForm" data-sentry-source-file="index.tsx" />
            <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                <button type="button" onClick={() => {
        setIsAuthenticated(false);
        sessionStorage.removeItem(translationTokenName);
      }} id="logout-translations">
                    logout
                </button>
            </Grid>
        </Grid>;
}