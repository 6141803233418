import React from 'react';
import { globalData } from 'pages/_app';
import { track } from 'utils/functions/track';
import Route from 'next/router';
import { buttonProps } from './types';
import styles from './button.module.scss';
export default function Button({
  id,
  color,
  margin,
  children,
  textPattern,
  state,
  block,
  styleButton,
  onClick,
  onDoubleClick,
  className,
  type,
  size = 'sm',
  ...extendedProps
}: buttonProps) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    track.onClick(`btn-click-${id}`, {
      pagePath: Route.pathname
    });
    return onClick && onClick(e);
  };
  return <button data-status className={`${styles.button} ${className} ${styles[size]}`} data-color={color || 'primary'} data-margin={margin} data-state={state} data-block={block} data-style-button={styleButton} onClick={handleClick} onDoubleClick={onDoubleClick} type={type === 'submit' ? 'submit' : 'button'} {...extendedProps} id={id} data-sentry-component="Button" data-sentry-source-file="index.tsx">
            {children}
        </button>;
}
export function EditTranslationsButton(props: buttonProps) {
  const {
    editTranslations
  } = globalData;
  const {
    children
  } = props;
  return <>
            {editTranslations ? <div style={{
      background: '#fff',
      color: '#000',
      padding: '0.25rem',
      margin: '0.15rem'
    }}>
                    {children}
                </div> : null}
            <Button {...props} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                {editTranslations ? 'cta' : children}
            </Button>
        </>;
}