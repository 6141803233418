import AuthContext from 'context/auth';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { getProfile } from 'utils/services/api/requests/userEnv';
export function useProfile() {
  const {
    isAuthenticated,
    token
  } = useContext(AuthContext);
  const {
    data: profile,
    isLoading
  } = useQuery(['profile', token], getProfile, {
    staleTime: 12000,
    enabled: !!isAuthenticated
  });
  return {
    isLoading,
    profile
  };
}