import React, { useContext } from 'react';
import { BsPen, BsTranslate } from 'react-icons/bs';
import Grid from 'components/uiKit/grid';
import DialogContext from 'context/dialog';
import Card from 'components/uiKit/card';
import TranslationsEnv, { TranslationItemI } from './environment';
import { Replaces } from '..';
interface Props {
  currentTranslation?: TranslationItemI;
  replaces?: Array<Replaces>;
}
export default function EditTranslation({
  currentTranslation,
  replaces
}: Props) {
  const {
    displayDialog
  } = useContext(DialogContext);
  function handleDisplayDialog() {
    displayDialog({
      dialogId: 'TRANSLATIONS-EDIT-DIALOG',
      content: <Grid>
                    <Card>
                        <TranslationsEnv currentTranslation={currentTranslation} />
                        <Grid padding={['pt-3']}>
                            {replaces?.length && <div>
                                        <span>
                                            this translation contains some variables available to use:
                                            <br />
                                            so, only add the code and on render this gonna be replaced by the real value:
                                            <br />
                                            ie:
                                            {' {{userName}}  => Joseph'}
                                        </span>
                                    </div>}

                           <Grid>
                                <div>
                                    {replaces && replaces?.map((replaceItem: Replaces) => {
                  return <React.Fragment key={replaceItem.code}>
                                                    <b>
                                                        {' '}
                                                        {replaceItem.code}
                                                    </b>
                                                    <br />
                                                </React.Fragment>;
                })}
                                </div>
                           </Grid>
                        </Grid>
                    </Card>
                </Grid>
    });
  }
  return <Grid width="fit-content" gap="0.5rem" onClick={() => handleDisplayDialog()} style={{
    cursor: 'pointer'
  }} data-sentry-element="Grid" data-sentry-component="EditTranslation" data-sentry-source-file="index.tsx">
            <BsTranslate data-sentry-element="BsTranslate" data-sentry-source-file="index.tsx" />
            <BsPen data-sentry-element="BsPen" data-sentry-source-file="index.tsx" />
        </Grid>;
}