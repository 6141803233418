import { translationTokenName } from 'components/textTranslated/editorTranslation/environment'
import Router from 'next/router'

export default function implementBackOfficeIntegration() {
    window.addEventListener('message', (event) => {
        const allowedOrigins = [
            'https://backoffice.tuohi.com',
            'https://bo-test.tuohi.com',
            'http://localhost:8009',
        ]

        if (!allowedOrigins.includes(event.origin)) {
            return
        }

        const { editTranslations, token } = event.data

        if (typeof editTranslations !== 'undefined') {
            const params = new URLSearchParams(window.location.search)
            if (editTranslations) {
                params.set('editTranslations', 'true')
                if (token) {
                    sessionStorage.setItem(translationTokenName, token)
                }
            } else {
                params.delete('editTranslations')
            }
            Router.replace({
                pathname: window.location.pathname,
                search: params.toString(),
            }, undefined, { shallow: true })
        }
    })
}
