import React from 'react';
export default function XIcon(props: React.SVGProps<SVGSVGElement>) {
  return <svg width={22} height={23} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="XIcon" data-sentry-source-file="xIcon.tsx">
            <g clipPath="url(#clip0_180_470)" data-sentry-element="g" data-sentry-source-file="xIcon.tsx">
                <path d="M12.3315 8.70872L19.6443 0H21.6483L13.2368 10.0429L22 23H15.4411L9.62406 14.3632L2.38286 23H0.343421L8.72032 13.0183L0 0H6.40675L12.3315 8.70872ZM19.3151 21.5686C14.7264 14.8064 10.1844 8.11472 5.64912 1.43219H2.66829C7.20807 8.18285 11.7109 14.8799 16.2093 21.5686H19.3151Z" fill="white" data-sentry-element="path" data-sentry-source-file="xIcon.tsx" />
            </g>
            <defs data-sentry-element="defs" data-sentry-source-file="xIcon.tsx">
                <clipPath id="clip0_180_470" data-sentry-element="clipPath" data-sentry-source-file="xIcon.tsx">
                    <rect width={22} height={23} fill="white" data-sentry-element="rect" data-sentry-source-file="xIcon.tsx" />
                </clipPath>
            </defs>
        </svg>;
}