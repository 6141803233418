import { useEffect } from 'react';
import { useRouter } from 'next/router';
const useRouteChangeTracker = () => {
  const router = useRouter();
  const normalizeRoute = (url: string) => {
    const dynamicPattern = /\[[^\]]+\]/g;
    return url.replace(dynamicPattern, match => {
      const pathParts = router.asPath.split('/');
      const dynamicIndex = url.split('/').indexOf(match);
      return pathParts[dynamicIndex] || match;
    });
  };
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const previousRoutes = sessionStorage.getItem('route-history');
      const routes = previousRoutes ? JSON.parse(previousRoutes) : [];
      const normalizedUrl = normalizeRoute(url);
      if (routes.length === 0 || routes[routes.length - 1] !== normalizedUrl) {
        routes.push(normalizedUrl);
        sessionStorage.setItem('route-history', JSON.stringify(routes));
      }
    };
    handleRouteChange(router.pathname);
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);
  return {
    getRouteHistory: () => {
      const routeHistory = sessionStorage.getItem('route-history');
      return routeHistory ? JSON.parse(routeHistory) : [];
    }
  };
};
export default useRouteChangeTracker;