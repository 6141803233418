/**
 * Save data to sessionStorage with an expiration time.
 * @param {string} key - The key under which the data is stored.
 * @param {any} value - The value to be stored.
 * @param {number} expireInMinutes - The expiration time in minutes.
 */
export function setSessionStorage(key: string, value: string | number, expireInDays: number) {
  const now = new Date()
  const data = {
    value,
    expiry: now.getTime() + expireInDays * 24 * 60 * 60 * 1000, // Convert days to milliseconds
  }
  sessionStorage.setItem(key, JSON.stringify(data))
}

/**
 * Retrieve data from sessionStorage and check if it's expired.
 * @param {string} key - The key of the data to retrieve.
 * @returns {any|null} - The stored value or null if expired or not found.
 */
export function getSessionStorage(key: string) {
  const storedData = sessionStorage.getItem(key)
  if (!storedData) return null

  const { value, expiry } = JSON.parse(storedData)
  const now = new Date()

  if (now.getTime() > expiry) {
    sessionStorage.removeItem(key) // Remove expired data
    return null
  }

  return value
}
