/* eslint-disable react/no-danger */
import Grid from 'components/uiKit/grid';
import Container from 'components/uiKit/grid/container';
import { useRouter } from 'next/router';
import styles from './styles.module.scss';
export default function MessagesStrip(props: any) {
  const {
    asPath
  } = useRouter();
  if (!props?.messages) {
    return null;
  }
  const {
    messages
  } = props;
  return <Grid data-path={asPath} className={styles.wrapper} data-sentry-element="Grid" data-sentry-component="MessagesStrip" data-sentry-source-file="index.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
        <Grid padding={['p-3']} gap="1rem" hidden={!messages.length} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          {messages.map((message: any) => {
          return <section key={message.id} dangerouslySetInnerHTML={{
            __html: message?.message
          }} />;
        })}
        </Grid>
      </Container>
    </Grid>;
}