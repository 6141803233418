import FooterLayout from 'components/footer';
import MessagesStrip from 'components/messages';
import Grid from 'components/uiKit/grid';
import GlobalContext from 'context/global';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
interface Props {
  children: React.ReactElement;
  propsSSR?: any;
}
export default function PagesWrapper(props: Props) {
  const {
    children,
    propsSSR
  } = props;
  const {
    asPath,
    query
  } = useRouter();
  const {
    type
  } = query;
  const {
    appType
  } = useContext(GlobalContext);
  const isHidden = type === 'collectPhoneEmail' || appType === 'mobile' && asPath.includes('/games/launch/') || asPath.includes('/tuohirulla/') || asPath.includes('/offline');
  return <Grid style={{
    backgroundColor: asPath.includes('/info/') && appType === 'mobile' ? 'var(--primary)' : '#fff'
  }} data-sentry-element="Grid" data-sentry-component="PagesWrapper" data-sentry-source-file="index.tsx">
      <Grid hidden={asPath === '/'} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <MessagesStrip {...propsSSR} data-sentry-element="MessagesStrip" data-sentry-source-file="index.tsx" />
      </Grid>
      <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">{children}</Grid>

      <Grid hidden={isHidden} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <FooterLayout data-sentry-element="FooterLayout" data-sentry-source-file="index.tsx" />
      </Grid>
    </Grid>;
}