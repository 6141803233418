/* eslint-disable no-console */
import React, { createContext, useEffect, useState } from 'react';
import { updateAppSizeVersion } from 'utils/functions/styles';
import { breakPointsType } from 'utils/globalTypes';
import { useRouter } from 'next/router';
import { DimensionsInterface, MobileNavbarLoggedSections, appTypeT, globalContextInterface, globalStateInterface } from './types';
const initialState: globalStateInterface = {
  appSize: 'sm',
  // default mobile view
  locale: 'fi-FI'
};
const initialDimensions: DimensionsInterface = {
  width: 0,
  height: 0
};
const GlobalContext = createContext<globalContextInterface>({
  state: initialState,
  setState: () => {},
  dimensions: initialDimensions,
  getAppType: () => 'mobile',
  appType: 'mobile',
  section: 'nav',
  setSection: () => {},
  previousRoute: null,
  handleGoBack: () => {},
  handlerOpenZendesk: () => {},
  fullScreen: false,
  setFullScreen: () => {}
});
interface Props {
  children: React.ReactNode;
  initialDeviceType: 'mobile' | 'tablet' | 'desktop';
}
export function GlobalProvider({
  children,
  initialDeviceType
}: Props) {
  const [state, setState] = useState({
    ...initialState,
    appSize: initialDeviceType === 'tablet' ? 'md' : initialDeviceType === 'desktop' ? 'lg' : 'sm' as breakPointsType
  });
  const [dimensions, setDimensions] = useState(initialDimensions);
  const [section, setSection] = useState<MobileNavbarLoggedSections>('nav');
  const [previousRoute, setPreviousRoute] = useState<string | null>(null);
  const router = useRouter();
  useEffect(() => {
    updateAppSizeVersion({
      setState,
      currentSize: state.appSize as breakPointsType
    });
  }, [state.appSize]);
  function getAppType(): appTypeT {
    const mobileSizes = ['xsm', 'sm'];
    const tabletSizes = ['md'];
    if (mobileSizes.includes(state.appSize)) {
      return 'mobile';
    }
    if (tabletSizes.includes(state.appSize)) {
      return 'tablet';
    }
    return 'desktop';
  }
  function handleGoBack() {
    if (previousRoute) {
      router.push(previousRoute);
    } else {
      router.back();
    }
  }
  function handlerOpenZendesk(name?: string) {
    if (typeof window !== 'undefined' && window.zE && typeof window.zE === 'function') {
      try {
        if (name) {
          // 28205188628369 refers to zendesk custom field "Nimi"
          window.zE('messenger:set', 'conversationFields', [{
            id: '28205188628369',
            value: name
          }], () => {
            window.zE('messenger', 'open');
          });
        } else {
          window.zE('messenger', 'open');
        }
      } catch (error) {
        console.error('Failed to open Zendesk widget:', error);
      }
    } else {
      console.error('Zendesk Web Widget is not loaded or not available.');
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
      const handleResize = () => {
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
    return () => {};
  }, []);
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setPreviousRoute(window?.location?.search ? `${window.location.pathname}${window.location.search}` : window.location.pathname);
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);
  const appType = getAppType();
  const [fullScreen, setFullScreen] = useState(appType === 'mobile');
  return <GlobalContext.Provider value={{
    state,
    setState,
    dimensions,
    getAppType,
    appType,
    section,
    setSection,
    previousRoute,
    handleGoBack,
    handlerOpenZendesk,
    fullScreen,
    setFullScreen
  }} data-sentry-element="unknown" data-sentry-component="GlobalProvider" data-sentry-source-file="index.tsx">
      {children}
    </GlobalContext.Provider>;
}
export default GlobalContext;