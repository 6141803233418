import '../styles/root.scss';
import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import PagesWrapper from 'layouts/pageWrapper';
import { GlobalProvider } from 'context/global';
import { AuthProvider } from 'context/auth';
import { DialogProvider } from 'context/dialog';
import { QueryClient, QueryClientProvider } from 'react-query';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { getRealScreenHeight } from 'utils/functions/styles';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Script from 'next/script';
import { useSmoothScroll } from 'utils/customHooks';
import IframeRedirect from 'layouts/iframeRedirect';
import { api } from 'utils/services/api';
import { headers } from 'utils/services/api/header';
import CookieNotification from 'components/cards/cookieNotification';
import implementBackOfficeIntegration from 'utils/functions/backOfficeIntegration';
import useZendeskChatVisibility, { handleChatClose } from 'utils/customHooks/useZendeskChatVisibility';
import useRouteChangeTracker from 'utils/customHooks/useRouteChangeTracker';
import ErrorBoundary from 'components/errorBoundary';
import { gaAnalytics } from 'utils/functions/track/gaAnalytics';
import { setSessionStorage } from 'utils/functions/sessionStorate';
const TITLE = 'Tuohi casino - Kierrätysvapaita kierroksia Tuohirullasta >>';
const DESCRIPTION = 'Pyöräytä Tuohirullasta kierrätysvapaita ilmaiskierroksia tai jopa 5000 €! Saa tuohet tilille minuutissa. Yli 2 000 peliä, klassikoista kuumimpiin uutuuksiin.';
const META_IMAGE = `${process.env.NEXT_PUBLIC_BASE_PATH}/assets/og-image/meta-image.png`;

// Set GTM_ID for production and staging environments
const GTM_ID = process.env.NODE_ENV === 'production' ? process.env.NEXT_PUBLIC_GTM_ID : process.env.NEXT_PUBLIC_GTM_ID_STAGE;
export const queryClient = new QueryClient();
export const globalData = {
  translations: [],
  editTranslations: false,
  pathname: '/',
  locale: 'fi-FI'
};
interface Props extends AppProps {
  seo: {
    id: number;
    pageURL: string;
    title: string;
    description: string;
    locale: string;
    enabled: boolean;
  };
}
export default function App(props: Props) {
  useSmoothScroll();
  useZendeskChatVisibility();
  useRouteChangeTracker();
  const {
    Component,
    pageProps,
    router
  } = props;
  const {
    cfItems,
    locale,
    translations,
    seo,
    gameSeoInfo,
    pathname,
    pageTitle,
    featureFlags,
    deviceType
  } = pageProps;
  const {
    query
  } = useRouter();
  const {
    editTranslations,
    btag,
    reviewFe,
    origin
  } = query;
  const originBackOffice = origin === 'backOffice';
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [isIframe, setIsIframe] = React.useState(reviewFe === 'none');
  useEffect(() => {
    const cookieResponse = localStorage.getItem('cookie-notification-response');
    if (cookieResponse === 'true') {
      setCookiesAccepted(true);
    }
  }, []);
  const handleAcceptCookies = () => {
    setCookiesAccepted(true);
  };

  // Store translations globally
  globalData.translations = translations;
  globalData.editTranslations = !!editTranslations;
  globalData.pathname = pathname;
  globalData.locale = locale;
  if (btag && typeof window !== 'undefined') {
    setSessionStorage('tuohi-btag', btag as string || '', 30);
  }
  const enableSolitics = featureFlags && featureFlags.length ? featureFlags.find((ff: any) => ff.name === 'solitics-frontend-sdk' && ff.enabled) : null;

  // Effects for various functionalities
  useEffect(() => {
    getRealScreenHeight();
    implementBackOfficeIntegration();
    if (window.top && window.self !== window.top && !originBackOffice) {
      setIsIframe(true);
    }
  }, []);
  if (cfItems && cfItems['cf-ipcountry']) {
    headers['User-Country'] = 'XX';
    api.defaults.headers.common = {
      ...api.defaults.headers.common,
      'User-Country': 'XX',
      'X-app-version': 'v0.0.3'
    };
  }
  useEffect(() => {
    if (cfItems) {
      window.cfItems = {
        ...cfItems,
        'x-test': 'test'
      };
      api.defaults.headers.common = {
        ...api.defaults.headers.common,
        'User-Country': 'XX',
        'X-app-version': 'v0.0.3'
      };
    }
  }, [cfItems]);
  useEffect(() => {
    gaAnalytics.trackPageView(pathname);
  }, [pathname]);
  return <>
      {/* Google Tag Manager (noscript) */}
      {GTM_ID && cookiesAccepted && <noscript>
          <iframe title="Tuohi Google Tag Manager" src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`} height="0" width="0" style={{
        display: 'none',
        visibility: 'hidden'
      }} />
        </noscript>}
      {/* End Google Tag Manager (noscript) */}

      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <title>
          {router.route === '/' ? TITLE // Static title for the home page
        : `${seo?.status > 299 ? `Tuohi ${gameSeoInfo ? `- ${gameSeoInfo?.displayName} ` : pageTitle || ''}` : seo?.title || pageTitle || 'Tuohi'} ${seo?.description ? '-' : ''} ${seo?.description || ''}`}
        </title>
        <meta name="description" content={router.route === '/' ? DESCRIPTION // Static description for the home page
      : seo?.description || ''} data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        {router.route === '/' && <link rel="canonical" href="https://www.tuohi.com/" />}

        {/* OG - Tags */}
        <meta property="og:type" content="website" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta property="og:url" content="https://www.tuohi.com/" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta property="og:site_name" content="Tuohi Kasino" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta property="og:title" content={TITLE} data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta property="og:description" content={DESCRIPTION} data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta property="og:image" content={META_IMAGE} data-sentry-element="meta" data-sentry-source-file="_app.tsx" />

        <meta property="twitter:card" content="summary_large_image" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta property="twitter:url" content="https://www.tuohi.com/" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta property="twitter:title" content={TITLE} data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta property="twitter:description" content={DESCRIPTION} data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta property="twitter:image" content={META_IMAGE} data-sentry-element="meta" data-sentry-source-file="_app.tsx" />

        <meta name="theme-color" content="#4A26FF" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="viewport" content="width=device-width, user-scalable=no" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
      </Head>
      <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-source-file="_app.tsx">
        <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="_app.tsx">
          <GlobalProvider initialDeviceType={deviceType} data-sentry-element="GlobalProvider" data-sentry-source-file="_app.tsx">
            <AuthProvider pageProps={pageProps} data-sentry-element="AuthProvider" data-sentry-source-file="_app.tsx">
              <DialogProvider data-sentry-element="DialogProvider" data-sentry-source-file="_app.tsx">
                <ToastContainer className="xs:!w-full md:!max-w-max" theme="colored" data-sentry-element="ToastContainer" data-sentry-source-file="_app.tsx" />
                {isIframe ? <IframeRedirect /> : router.route === '/404' || router.route === '/_error' ? <Component {...pageProps} /> : router.route?.includes('/login') || router.route?.includes('/register') || router.route?.includes('/account/forgot-password') || router.route?.includes('/account/reset-password') ? <Component {...pageProps} /> : <PagesWrapper>
                            <Component {...pageProps} />
                          </PagesWrapper>}
                <CookieNotification onAccept={handleAcceptCookies} data-sentry-element="CookieNotification" data-sentry-source-file="_app.tsx" />
              </DialogProvider>
            </AuthProvider>
          </GlobalProvider>
        </QueryClientProvider>
      </ErrorBoundary>

      <Script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=8515ba57-0a36-4c18-9780-a59d1be81245" strategy="lazyOnload" // Load after the page load is complete
    onLoad={() => {
      const event = new Event('zendeskLoaded');
      window.dispatchEvent(event);
      setTimeout(() => {
        handleChatClose();
      }, 1000); // Keep this if the timeout is necessary
    }} onError={() => console.error('Failed to load Zendesk script')} data-sentry-element="Script" data-sentry-source-file="_app.tsx" />

      {enableSolitics && <Script id="solitcs-analytics" src="https://sdk.solitics.com/oapit.min.js" strategy="lazyOnload" // Load after the page load is complete
    onError={() => console.error('Failed to load Solitics script')} // Error handling for script loading
    />}

      {cookiesAccepted && GTM_ID && <>
          <Script id="google-analytics" dangerouslySetInnerHTML={{
        __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GTM_ID}');
              `
      }} strategy="afterInteractive" async // Async loading to improve performance
      />
          <Script id="ga-initial" dangerouslySetInnerHTML={{
        __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${GTM_ID}');
              `
      }} strategy="afterInteractive" async // Async loading to improve performance
      onError={() => console.error('Failed to initialize Google Analytics')} // Error handling for GA initialization
      />
        </>}

    </>;
}