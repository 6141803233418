const GTM_ID = process.env.NODE_ENV === 'production'
  ? process.env.NEXT_PUBLIC_GTM_ID
  : process.env.NEXT_PUBLIC_GTM_ID_STAGE

interface TrackingEvent {
  action: string
  category: string
  label?: string
  value?: string | number
  userId?: string | number
  buttonId?: string
}

type ButtonClickEvent = Pick<TrackingEvent, 'category' | 'value' | 'userId' | 'buttonId'>

export const gaAnalytics = {
  // Track a pageview
  trackPageView: (url: string) => {
    if (!url) return

    window?.gtag?.('config', GTM_ID, {
      page_path: url,
    })
  },
  trackEvent: ({
    action,
    category,
    label,
    value,
    userId,
  }: TrackingEvent) => {
    window?.gtag?.('event', action, {
      event_category: category,
      event_label: label,
      value,
      userId,
    })
  },
  trackCloseButtonClick: ({
    category,
    value,
    userId,
  }: ButtonClickEvent) => {
    window?.gtag?.('event', 'button-click', {
      event_category: category,
      event_label: 'Close button click',
      value,
      user_id: userId,
    })
  },
  trackButtonClick: ({
    category,
    buttonId,
    value,
    userId,
  }: ButtonClickEvent) => {
    window?.gtag?.('event', 'button-click', {
      event_category: category,
      event_label: 'Button click',
      value,
      user_id: userId,
      button_id: buttonId,
    })
  },
  // Update the user_id dynamically
  setUserId(userId: string) {
    window?.gtag?.('config', GTM_ID, {
      user_id: userId,
    })
  },
}
