import Typography from 'components/uiKit/typography';
import React, { ChangeEventHandler, HTMLInputTypeAttribute, useState } from 'react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { globalData } from 'pages/_app';
import Grid from 'components/uiKit/grid';
import { typographySize } from 'components/uiKit/typography/types';
import Checkbox from './variants/checkbox';
import styles from './input.group.module.scss';
import TextAreaGroup from './variants/textArea';
export interface uiKitInputProps {
  label?: string | undefined | any;
  value?: string | ReadonlyArray<string> | number | undefined | boolean;
  name: string;
  id: string;
  inputType?: HTMLInputTypeAttribute | 'textarea';
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  status?: 'error' | 'success' | 'warning' | 'info' | '';
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  feedback?: React.ReactNode;
  styles?: React.CSSProperties;
  noEditTranslation?: boolean;
  fontSize?: typographySize | Array<typographySize>;
  disableLabelAnimated?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  readOnly?: any;
  dataInput?: any;
}
export default function InputGroup(props: uiKitInputProps) {
  const {
    label,
    value,
    name,
    id,
    inputType: inputTypeProps,
    onChange,
    status,
    inputProps,
    feedback,
    styles: stylesProps,
    noEditTranslation,
    fontSize,
    onBlur,
    readOnly,
    onFocus,
    dataInput = 'false'
  } = props;
  const [inputType, setInputType] = useState(inputTypeProps || 'text');
  const {
    editTranslations
  } = globalData;
  const formatDateString = (dateString: any) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate() + 1}`.slice(-2);
    return `${year}-${month}-${day}`;
  };
  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    if (event.target.type === 'date') {
      event.target.value = formatDateString(event.target.value);
    }
    if (onChange) {
      onChange(event);
    }
  };
  function handleClick(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    if (inputType === 'date' || inputProps?.type === 'date') {
      const {
        target
      }: any = e as any; // this prevent eslint warn
      if (!inputProps?.readOnly) {
        target?.showPicker();
      }
    }
    if (editTranslations && inputProps?.onClick) {
      if (e?.target === e?.currentTarget) {
        inputProps?.onClick(e);
      }
    } else if (inputProps?.onClick) {
      inputProps?.onClick(e);
    }
  }
  return inputType === 'checkbox' ? <Checkbox {...props} /> : inputType === 'textarea' ? <TextAreaGroup {...props as any} /> : <>
                    {editTranslations && !noEditTranslation ? <Grid>
                                    <Grid className={styles['animated-label']} data-input={dataInput}>
                                        {typeof label === 'string' ? <Typography translateGroup="input-group-label" translateKey={label} size="sm" /> : label}
                                    </Grid>
                                    <input value={value as any} onChange={handleChange} onBlur={onBlur} disabled={readOnly} name={name} id={id} {...inputProps} onClick={e => handleClick(e)} type={inputType || inputProps?.type || 'text'} onFocus={onFocus} />
                                    <div data-status={status} data-font-size={fontSize || 'sm'} className={styles['input-group-wrapper']} style={{
        ...stylesProps
      }}>
                                        {feedback && <div className={styles.feedback}>
                                                    <Typography size="xsm">
                                                        {feedback}
                                                    </Typography>
                                                </div>}
                                    </div>
                                </Grid> : <div data-status={status} data-font-size={fontSize || 'sm'} className={styles['input-group-wrapper']} style={{
      ...stylesProps
    }}>
                                    <label data-filled={!!value} className={`${!label && '!mt-0'}`}>
                                        <input value={value as any} onChange={handleChange} disabled={readOnly} placeholder={label} onBlur={onBlur} name={name} id={id} {...inputProps} onClick={e => handleClick(e)} type={inputType || inputProps?.type || 'text'} onFocus={onFocus} />
                                        <div data-ready-only={readOnly} id="animated-label-value" className={styles['animated-label']} data-input={dataInput}>
                                            {typeof label === 'string' ? <Typography translateGroup="input-group-label" translateKey={label} size="sm" /> : label}
                                        </div>
                                        <div className={styles['eye-icon']} hidden={inputTypeProps !== 'password'}>
                                            {inputType === 'password' ? <BsEye onClick={() => setInputType('text')} /> : <BsEyeSlash onClick={() => setInputType('password')} />}
                                        </div>
                                    </label>
                                    {feedback && <div className={styles.feedback}>
                                                <Typography size="xsm">
                                                    {feedback}
                                                </Typography>
                                            </div>}
                                </div>}
                </>;
}