import * as NextLink from 'next/link';
import { globalData } from 'pages/_app';
import React, { JSXElementConstructor, ReactElement } from 'react';
interface Props extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof NextLink.LinkProps>, NextLink.LinkProps, React.RefAttributes<HTMLAnchorElement> {
  children: ReactElement<any, string | JSXElementConstructor<any>> | any;
}
const OriginalLink = NextLink.default;
export default function Link({
  children,
  ...props
}: Props) {
  const {
    editTranslations
  } = globalData;
  if (editTranslations) {
    return <>
                <div style={{
        background: '#fff',
        color: '#000',
        padding: '0.25rem',
        margin: '0.15rem'
      }}>
                    {children}
                </div>
                <OriginalLink {...props}>
                    <>
                        cta
                    </>
                </OriginalLink>
            </>;
  }
  return <OriginalLink {...props} data-sentry-element="OriginalLink" data-sentry-component="Link" data-sentry-source-file="index.tsx">
            {children}
        </OriginalLink>;
}