import { getAPIClient, getCMSApi } from './axios'

export const api = getAPIClient()
export const cmsApi = getCMSApi()

export const useQueryDefaultSetting = {
    staleTime: 120000, // 2 minutes to refresh the calls
}

if (process.env.NODE_ENV === 'development') {
    api.defaults.headers.common['User-Country'] = 'XX'
}
