import { Replaces } from 'components/textTranslated'
import React from 'react'
import { ToastOptions } from 'react-toastify'

export type bannerT =
  | 'login-success'
  | 'login-failure'
  | 'login-closed'
  | 'loginDeposit-success'
  | 'loginDeposit-failure'
  | 'loginDeposit-closed'
  | 'loginDeposit-close'
  | 'withdrawal-success'
  | 'withdrawal-failure'
  | 'withdrawal-closed'
  | 'withdrawal-blocked'
  | 'withdrawal-wagering-blocked'
  | 'deposit-success'
  | 'deposit-failure'
  | 'deposit-closed'
  | 'collectPhoneEmail'
  | 'collectPhoneEmail-success'
  | 'collectPhoneEmail-failure'
  | 'confirmEmail'
  | 'confirmEmail-success'
  | 'confirmEmail-failure'
  | 'logout'
  | 'withdrawalManualApproval'
  | 'tuohirulla-bonus'
  | 'genericError';

export type bannerStatusT = 'success' | 'failure' | 'closed';

export interface statusBannerI {
  autoClose: number;
  nextBanner?: bannerT;
  status: bannerStatusT;
  imgUrl?: string;
}

export interface statusPopupI {
  group?: string;
  title?: string;
  description?: string;
  icon?: React.JSX.Element;
  options?: ToastOptions;
  buttons?: React.JSX.Element | React.ReactElement;
}

export type PopupI = Omit<statusPopupI, 'options' & 'buttons'> & {
  footer?: React.JSX.Element;
  replaces?: Replaces[];
}

export const defaultAutoCloseTimer: number = 5000
