import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/nextjs';
interface ErrorBoundaryProps {
  children: ReactNode;
}
class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.captureException(error, {
      extra: {
        componentStack: errorInfo.componentStack
      }
    });
  }
  render() {
    const {
      children
    } = this.props;
    return children;
  }
}
export default ErrorBoundary;