import axios from 'axios'
import { externalGetAuthStatus } from 'context/auth'
import * as Sentry from '@sentry/nextjs'
import { headers } from './header'

export function getAPIClient() {
  const api = axios.create({
    headers: { ...headers },
  })
  api.interceptors.response.use(async (response: any) => {
    const session = response?.headers['session-status']
    // TODO : control session at auth context
    return response
  }, (error) => {
    if (error?.response?.status === 401) {
      externalGetAuthStatus.logout()
    }

    // Enrich the error with additional context before sending to Sentry
    Sentry.withScope((scope) => {
      // Add HTTP status code as a tag
      scope.setTag('http.status_code', error.response?.status || 'unknown')
      // Add the request URL
      scope.setTag('http.url', error.config?.url || 'unknown')

      // Add request details to extra data
      scope.setExtra('request', {
        method: error.config?.method,
        url: error.config?.url,
        headers: error.request?.headers ?? error.config?.headers,
        data: error.request?.body ?? error.config?.data,
      })

      // Add response details to extra data
      scope.setExtra('response', {
        status: error.response?.status,
        headers: error.response?.headers,
        data: error.response?.data,
      })

      // Capture the exception with the enriched scope
      Sentry.captureException(error)
    })

    return Promise.reject(error)
  })
  return api
}

export const getCMSApi = () => {
  const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_CMS_ENDPOINT,
    timeout: 1000 * 5,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `users API-Key ${process.env.NEXT_PUBLIC_CMS_API_KEY}`,
    },
  })

  // Add a response interceptor for error handling - Incase API fails
  instance.interceptors.response.use(
    (response) => {
      // If the request is successful, simply return the response
      return response
    },
    (error) => {
      console.error('CMS API request failed:', error)

      // Return a default fallback value when the API fails
      return Promise.resolve({
        data: {
          message: 'Fallback data due to API failure.',
          content: [],
        },
      })
    },
  )
  return instance
}
