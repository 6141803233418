import { cmsApi } from '../..'
import { payloadCMSUrl } from '../urls'

export async function getSiteConfig() {
  const res = await cmsApi.get(payloadCMSUrl.globals.siteConfig)
  return res?.data
}

export async function getPostBySlug(slug?: string) {
  let apiUrl = payloadCMSUrl.collections.posts
  if (slug) {
    apiUrl = `${apiUrl}?where[slug][equals]=${slug}&[_status][equals]=published`
  }
  const res = await cmsApi.get(apiUrl)
  return res?.data
}

export async function getPosts() {
  return getPostBySlug()
}
