import { textTranslated } from 'components/textTranslated';
import React from 'react';
import { globalData } from 'pages/_app';
import { getTranslationsFromFE } from 'utils/services/translations';
import { useQuery } from 'react-query';
import { useRouter } from 'next/router';
import { locales } from 'utils/globalTypes';
import styles from './typography.module.scss';
import { typographyProps } from './types';
declare global {
  interface Window {
    translations: any;
  }
}
export default function Typography(props: typographyProps) {
  const {
    size,
    children,
    additionalChildren,
    weight,
    elementType,
    margin,
    color,
    hoverColor,
    style: styleProps,
    translateGroup,
    translateKey,
    replaces,
    returnDefault,
    defaultContent,
    algin,
    className,
    ...tProps
  } = props;
  const style = {
    fontWeight: weight || 400
  };
  const elementsProps = {
    'data-size': size || 'normal',
    'data-margin': margin || 'normal',
    'data-algin': algin || '',
    className: `${styles.typography} ${color === 'golden-gradient' ? styles['golden-gradient'] : ''} ${className}`,
    style: {
      ...style,
      ...styleProps,
      ...(color && color !== 'golden-gradient' && {
        color
      })
    },
    onMouseEnter: (e: any) => {
      e.target.style.color = hoverColor;
    },
    onMouseLeave: (e: any) => {
      e.target.style.color = color;
    },
    ...tProps
  };
  if (typeof window !== 'undefined' && translateKey && translateGroup) {
    const item = {
      translateGroup,
      translateKey,
      pathname: globalData.pathname
    };

    // Check if window.translations exists and is an array
    if (window.translations && Array.isArray(window.translations)) {
      // Check if the item already exists in window.translations
      const isDuplicate = window.translations.some(existingItem => existingItem.translateGroup === item.translateGroup && existingItem.translateKey === item.translateKey && existingItem.pathname === globalData.pathname);
      // Add the item only if it's not a duplicate
      if (!isDuplicate) {
        window.translations.push(item);
      }
    } else {
      // Initialize window.translations as an array with the item
      window.translations = [item];
    }
  }
  const elements = (content: React.ReactNode) => ({
    span: <span {...elementsProps}>
                {content}
                {additionalChildren}
            </span>,
    p: <p {...elementsProps}>
                {content}
                {additionalChildren}
            </p>,
    h1: <h1 {...elementsProps}>
                {content}
                {additionalChildren}
            </h1>,
    h2: <h2 {...elementsProps}>
                {content}
                {additionalChildren}
            </h2>,
    h3: <h3 {...elementsProps}>
                {content}
                {additionalChildren}
            </h3>,
    h4: <h4 {...elementsProps}>
                {content}
                {additionalChildren}
            </h4>,
    h5: <h5 {...elementsProps}>
                {content}
                {additionalChildren}
            </h5>,
    h6: <h6 {...elementsProps}>
                {content}
                {additionalChildren}
            </h6>
  });

  // const { data: translationsCS } = useQuery(`translations-${locale}`, () => getTranslationsFromFE(locale as locales), {
  //     staleTime: 60000000,
  //     refetchOnWindowFocus: false,
  // })

  if (translateGroup && translateKey) {
    const translated = textTranslated({
      group: translateGroup,
      key: translateKey,
      replaces: replaces && replaces,
      returnDefault,
      defaultContent
      // data: (translationsCS && locale) && translationsCS[locale],
    });
    if (typeof translated === 'object') {
      return {
        ...translated,
        props: {
          ...translated.props,
          ...elementsProps
        }
      };
    }
    return elements(translated)[elementType || 'span'];
  }
  return elements(children || (returnDefault === 'nothing' ? '' : translateKey))[elementType || 'span'];
}