import { useEffect, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';
import styles from './dialog.module.scss';
export interface DialogInterface {
  children?: any;
  onClose?: (() => void) | Function | null;
  closeColor?: string;
  closeStyle?: any;
  closeSize?: number;
  anchor?: 'center' | 'left' | 'right' | 'top' | 'position';
  anchorPosition?: {
    x: number;
    y: number;
    element: HTMLElement;
  };
  height?: '100' | 'UNDER-NAVBAR';
  displayClose?: boolean;
  disableOverlayClose?: boolean;
  hide?: boolean;
  dialogStylesBox?: any;
}
let mouseDown: any = null;
export default function Dialog({
  children,
  onClose,
  closeColor,
  closeStyle,
  closeSize,
  anchor,
  height,
  displayClose,
  disableOverlayClose,
  anchorPosition,
  hide,
  dialogStylesBox
}: DialogInterface) {
  const dialogRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({
    x: anchorPosition?.x ?? 0,
    y: anchorPosition?.y ?? 0,
    render: false
  });
  function close() {
    if (hide) return;
    if (onClose) onClose();
  }
  async function overClickClose(e: any) {
    if (hide) return;
    if (e?.target === e?.currentTarget && e?.target === mouseDown?.target) {
      close();
    }
  }
  function keyHandler(e: KeyboardEvent) {
    if (hide) return;
    if (e.key === 'Escape' && onClose && !disableOverlayClose) {
      onClose();
    }
  }
  useEffect(() => {
    const keyHandlerWrapper = (e: any) => keyHandler(e);
    window.addEventListener('keyup', keyHandlerWrapper);
    return () => {
      window.removeEventListener('keyup', keyHandlerWrapper);
      document.body.style.overflow = '';
    };
  }, []);
  useEffect(() => {
    if (!anchorPosition?.element) return;
    const updatePosition = () => {
      const rect = anchorPosition.element.getBoundingClientRect();
      setPosition(current => {
        const pos = {
          x: rect.left + anchorPosition.x,
          y: rect.top + anchorPosition.y,
          render: true
        };
        return pos;
      });
    };
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          updatePosition();
        }
      });
    });
    observer.observe(anchorPosition.element);
    window.addEventListener('scroll', updatePosition);
    window.addEventListener('resize', updatePosition);
    updatePosition();

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };
  }, [anchorPosition?.element]);
  return <div tabIndex={-1} data-height={height} data-anchor={anchor || ''} data-hidden={!!hide} className={styles['dialog-wrapper']} onClick={e => {
    if (!disableOverlayClose) overClickClose(e);
  }} onMouseDown={e => {
    mouseDown = e;
  }} data-sentry-component="Dialog" data-sentry-source-file="index.tsx">
            <div id="dialog-content-box" className={styles.dialog} style={anchor === 'position' ? {
      left: position.x,
      top: position.y,
      opacity: position.render ? 1 : 0
    } : {
      ...dialogStylesBox
    }} ref={dialogRef}>
                {displayClose && <div className={styles['close-section']} style={{
        ...closeStyle
      }}>
                        <MdClose color={closeColor || 'var(--primary)'} onClick={() => close()} size={closeSize || 24} />
                    </div>}
                {children}
            </div>
        </div>;
}