import axios from 'axios'

function solitics() {
    const { $solitics } = typeof window !== 'undefined' ? window as any : {} as any
    return $solitics
}
interface LoginObject {
    memberId: string;
    keyValue?: string;
    keyType?: string;
    email: string;
    brand?: string;
    popupToken?: string;
    brandToken?: string;
    branch?: string;
    customFields?: any;
}
function parseProperties(properties: any) {
    try {
        return JSON.stringify(properties)
    } catch (error) {
        return '{}'
    }
}
function randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}
const eventTimes: Record<string, number> = {}
const throttleEmit = (delayInSeconds: number, eventName: string, amount: string | number, properties?: object) => {
    const now = Date.now()
    const lastTime = eventTimes[eventName] || 0
    if (now - lastTime >= delayInSeconds * 1000) {
        solitics()?.emit(eventName, amount, parseProperties(properties))
        eventTimes[eventName] = now
    }
}
export const track = typeof window !== 'undefined'
    ? ({
        emit: (eventName: string, amount: string | number, properties?: object) => {
            setTimeout(() => {
                throttleEmit(5, eventName, amount, properties)
            }, randomIntFromInterval(200, 500))
        },
        loginSuccess: (loginObj: LoginObject) => {
            solitics()?.loginSuccess(loginObj)
        },
        onLogout: () => {
            solitics()?.onLogout()
        },
        onPageEnter: (pageName: string, customFields: object) => {
            solitics()?.onPageEnter(pageName, JSON.stringify({
                ...customFields,
                branch: 'tuohi',
                brand: process.env.NEXT_PUBLIC_SOLITCS_BRAND_TOKEN,
                popupToken: process.env.NEXT_PUBLIC_SOLITCS_POPUP_TOKEN,
            }))
            setTimeout(() => {
                const pageWrapper = document.getElementById('main-wrapper-content')
                if (pageWrapper) {
                    pageWrapper.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    })
                }
            }, 200)
        },
        onPageLeave: (pageName: string, customFields: object) => {
            solitics()?.onPageLeave(pageName, JSON.stringify({
                ...customFields,
                branch: 'tuohi',
                brand: process.env.NEXT_PUBLIC_SOLITCS_BRAND_TOKEN,
                popupToken: process.env.NEXT_PUBLIC_SOLITCS_POPUP_TOKEN,
            }))
        },
        onClick: (objectName: string, customFields: object) => {
            solitics()?.onClick(objectName, JSON.stringify({
                ...customFields,
                branch: 'tuohi',
                brand: process.env.NEXT_PUBLIC_SOLITCS_BRAND_TOKEN,
                popupToken: process.env.NEXT_PUBLIC_SOLITCS_POPUP_TOKEN,
            }))
        },
        onError: (action: string, crashData: object) => {
        },
    })
    : ({
        emit: (eventName: string, amount: string | number, properties?: object) => {
        },
        loginSuccess: (loginObj: LoginObject) => {
        },
        onLogout: () => {
        },
        onPageEnter: (pageName: string, customFields: object) => {
        },
        onPageLeave: (pageName: string, customFields: object) => {
        },
        onClick: (objectName: string, customFields: object) => {
        },
        onError: (action: string, crashData: object) => {
        },
    })
