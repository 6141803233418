import React, { createContext, useEffect, useState } from 'react';
import GenericDialog from 'components/uiKit/dialogs/genericDialog';
import { useRouter } from 'next/router';
import { displayDialogInterface } from './types';
interface Props {
  children: React.ReactNode;
}
export type DisplayDialog = (props: displayDialogInterface, hiddenList?: Array<string>) => void;
export type RemoveDialog = (dialogId: string) => void;
interface DialogContextInterface {
  dialogs: displayDialogInterface[];
  displayDialog: DisplayDialog;
  removeDialog: RemoveDialog;
  removeAllDialog: Function;
  unHideDialogs: (list: Array<string>) => void;
}
const DialogContext = createContext<DialogContextInterface>({
  dialogs: [],
  displayDialog: (props: displayDialogInterface, hiddenList?: Array<string>) => {},
  removeDialog: (dialogId: string) => {},
  removeAllDialog: () => {},
  unHideDialogs: () => {}
});
export const externalDialogCall = {
  displayDialog: (dProps: displayDialogInterface, hiddenList?: Array<string>) => {},
  removeDialog: (dialogId: string) => {},
  removeAllDialog: () => {},
  unHideDialogs: (list: Array<string>) => {}
};
export function DialogProvider(props: Props) {
  const {
    children
  } = props;
  const [dialogs, setDialogs] = useState<displayDialogInterface[]>([]);
  const {
    asPath
  } = useRouter();
  function displayDialog(dProps: displayDialogInterface, hiddenList?: Array<string>) {
    setDialogs((current: any) => {
      const newCurrent = current.map((dialog: displayDialogInterface) => ({
        ...dialog,
        hide: hiddenList?.includes(dialog.dialogId) ? true : dialog.hide
      }));
      const dialogExists: displayDialogInterface = newCurrent.find((dialog: displayDialogInterface) => dialog.dialogId === dProps.dialogId) as displayDialogInterface;
      if (dialogExists) {
        return [...[...newCurrent].filter((dialog: displayDialogInterface) => dialog.dialogId !== dProps.dialogId), {
          ...dProps
        }];
      }
      return [{
        ...dProps
      }, ...newCurrent];
    });
  }
  function unHideDialogs(list: Array<string>) {
    setDialogs((current: any) => {
      const newCurrent = current.map((dialog: displayDialogInterface) => ({
        ...dialog,
        hide: list.includes(dialog.dialogId) ? false : dialog.hide
      }));
      return newCurrent;
    });
  }
  function removeDialog(dialogId: string) {
    setDialogs((currentDialogs: any) => {
      const dialogToRemove: displayDialogInterface = currentDialogs.find((dialog: displayDialogInterface) => dialog.dialogId === dialogId);
      if (dialogToRemove) {
        if (dialogToRemove?.onCloseCallback) {
          dialogToRemove.onCloseCallback();
        }
        if (dialogToRemove?.dialogProps?.onClose) {
          dialogToRemove?.dialogProps?.onClose();
        }
      }
      return currentDialogs.filter((dialog: displayDialogInterface) => dialog.dialogId !== dialogId);
    });
  }
  function removeAllDialog() {
    setDialogs([]);
  }
  externalDialogCall.displayDialog = displayDialog;
  externalDialogCall.removeDialog = removeDialog;
  externalDialogCall.removeAllDialog = removeAllDialog;
  externalDialogCall.unHideDialogs = unHideDialogs;
  useEffect(() => {
    removeAllDialog();
  }, [asPath]);
  return <DialogContext.Provider value={{
    dialogs,
    displayDialog,
    removeDialog,
    removeAllDialog,
    unHideDialogs
  }} data-sentry-element="unknown" data-sentry-component="DialogProvider" data-sentry-source-file="index.tsx">
            {children}
            {dialogs?.map((dialog: displayDialogInterface) => {
      return <GenericDialog key={dialog.dialogId} {...dialog?.dialogProps} hide={dialog?.hide} onClose={() => removeDialog(dialog.dialogId)}>
                            {dialog.content}
                        </GenericDialog>;
    })}
        </DialogContext.Provider>;
}
export default DialogContext;