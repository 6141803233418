import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import React, { useContext, useEffect } from 'react';
import AppLogo from 'assets/logo/appLogo';
import Link from 'components/customLink';
import GlobalContext from 'context/global';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useProfile } from 'utils/customHooks/useProfile';
import { gaAnalytics } from 'utils/functions/track/gaAnalytics';
import { useQuery } from 'react-query';
import { getSiteConfig } from 'utils/services/api/requests/cms';
import { LinksContacts } from './linksContacts';
import { LinksStandards } from './linksStandards';
import { SocialMedia } from './socialMedia';
import styles from './styles.module.scss';
const BeGambleSize = {
  mobile: {
    width: 340,
    height: 44
  },
  tablet: {
    width: 340 / 1.5,
    height: 44 / 1.5
  },
  desktop: {
    width: 340,
    height: 44
  }
};
const CertificSize = {
  mobile: {
    width: 95,
    height: 78
  },
  tablet: {
    width: 95 / 1.5,
    height: 78 / 1.5
  },
  desktop: {
    width: 95,
    height: 78
  }
};
function FooterLayout() {
  const {
    appType,
    fullScreen
  } = useContext(GlobalContext);
  const router = useRouter();
  const {
    query,
    push,
    pathname
  } = useRouter();
  const {
    profile
  } = useProfile();
  const {
    type
  } = query;
  const {
    data: siteConfig
  } = useQuery(['site-config'], () => getSiteConfig(), {
    refetchInterval: 60 * 1000
  });
  useEffect(() => {
    if (siteConfig?.maintenance?.enable) {
      push('/offline');
    }
  }, [siteConfig]);
  const isHidden = ['/offline', '/tuohirulla'].includes(router.pathname) || router.pathname === '/games/launch/[gameName]' && fullScreen;
  useEffect(() => {
    gaAnalytics.setUserId(profile?.id);
    if (profile && window !== undefined) {
      window.zE?.('messenger', 'loginUser', (cb: any) => {
        fetch(`/api/generate-zendesk-token?name=${[profile.firstName, profile.lastName].join(' ')}&email=${profile.email}&userId=${profile.id}`).then(async res => {
          const data = await res.json();
          cb(data.token);
        });
      });
    }
  }, [profile]);
  return <Grid className={pathname.includes('/info/about') && 'bg-white'} data-sentry-element="Grid" data-sentry-component="FooterLayout" data-sentry-source-file="index.tsx">
      <footer data-remove-radius={type === 'collectPhoneEmail'} className={styles.footer} hidden={isHidden}>
        <Grid gap="2rem" className={styles.contant} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Grid gap="2rem" verticalAlgin="stretch" responsiveWidth={{
          sm: 100,
          md: 'calc(50% - 1rem)'
        }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <Grid verticalAlgin="center" horizontalAlgin={appType === 'mobile' ? 'center' : 'flex-start'} responsiveWidth={{
            sm: 100,
            md: 'calc(50% - 1rem)'
          }} className={styles.footerLogo} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
              <Link href="/" aria-label="to-home" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                <AppLogo color="white" width="100%" height="100%" style={{
                maxWidth: '14.75rem'
              }} data-sentry-element="AppLogo" data-sentry-source-file="index.tsx" />
              </Link>
            </Grid>

            <SocialMedia data-sentry-element="SocialMedia" data-sentry-source-file="index.tsx" />
            <LinksContacts data-sentry-element="LinksContacts" data-sentry-source-file="index.tsx" />
            <LinksStandards data-sentry-element="LinksStandards" data-sentry-source-file="index.tsx" />
          </Grid>
          <Grid gap="2.625rem" verticalAlgin="stretch" responsiveWidth={{
          sm: 100,
          md: 'calc(50% - 1rem)'
        }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <Grid gap="2rem" wrap={appType === 'mobile' ? 'wrap' : 'nowrap'} verticalAlgin="center" horizontalAlgin={appType === 'mobile' ? 'center' : 'flex-start'} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
              <div className={styles.certificateContainer}>
              <Link href="https://www.isleofmangsc.com/gambling/online-gambling-licence-holders/" passHref target="_blank" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                <Image height={CertificSize[appType].height} width={CertificSize[appType].width} alt="certificate" src="/footer/certificate.webp" sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
              </Link>
              <Link href="https://www.gambleaware.org/" passHref target="_blank" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                <Image height={BeGambleSize[appType].height} width={BeGambleSize[appType].width} sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" src="/footer/be-aware.webp" alt="be aware" priority={false} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
              </Link>
              </div>
            </Grid>
            <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
              <Typography translateGroup="footer" translateKey="footer-terms" style={{
              fontSize: 'calc(14 / 16 * 1rem)'
            }} data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
            </Grid>
          </Grid>
        </Grid>
        {appType === 'mobile' && <div style={{
        height: '10vh'
      }} />}
      </footer>
    </Grid>;
}
export default FooterLayout;