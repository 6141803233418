import React, { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
const Editor = dynamic(() => import('./Editor'), {
  ssr: false
});
interface onChangeI {
  target: {
    value: string;
    id: string;
    name: string;
  };
}
export interface EditorV2Props {
  value: string;
  onChange: (event: onChangeI) => void;
  id: string;
  name: string;
  label: string | React.ReactElement;
  useNativeElements?: boolean;
}
export default function EditorV2(props: EditorV2Props) {
  return <Editor {...props} data-sentry-element="Editor" data-sentry-component="EditorV2" data-sentry-source-file="index.tsx" />;
}