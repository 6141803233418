/* eslint-disable no-unreachable */
import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import AppLogoBeta from './appLogoBeta';
interface Props extends React.SVGProps<SVGSVGElement> {
  color: 'white' | 'blue';
}
const durationMultiplier = 1;
export default function AppLogo(props: Props) {
  const {
    color,
    ...nProps
  } = props;
  const pathRef = useRef<SVGPathElement>(null);
  useEffect(() => {
    const tl = gsap.timeline({
      defaults: {
        duration: durationMultiplier * 1,
        ease: 'power2.inOut',
        force3D: true
      },
      repeat: -1,
      repeatDelay: 0
    });
    tl.to(pathRef.current, {
      y: -500,
      duration: durationMultiplier * 0,
      ease: 'power2.in'
    }).to(pathRef.current, {
      y: 0,
      duration: durationMultiplier * 1,
      ease: 'elastic.out(1, 0.3)'
    }).to(pathRef.current, {
      y: 0,
      duration: durationMultiplier * 3
    }).to(pathRef.current, {
      y: 500,
      duration: durationMultiplier * 0.25
    });
  }, []);
  return <svg width={2328} height={602} viewBox="0 0 2328 602" fill="none" xmlns="http://www.w3.org/2000/svg" {...nProps} data-sentry-element="svg" data-sentry-component="AppLogo" data-sentry-source-file="appLogo.tsx">
            <path d="M1332.4 478.5C1284.4 472 1245.2 443.4 1229.2 403.4C1221.2 383.3 1221.6 388.5 1221.2 287.8L1220.9 198H1258.5H1296V281.8C1296 360.1 1296.1 366.1 1297.9 374.7C1300.4 386.6 1304.6 394.5 1312.6 401.9C1321.6 410.4 1331.4 414.1 1346.6 414.8C1366.5 415.7 1380.1 411 1390.8 399.5C1397.8 392.1 1402.5 381.5 1404 369.7C1404.5 365.7 1405 325.5 1405 280.3V198H1442.5H1480V283.3C1480 352.5 1479.7 370.3 1478.5 378.1C1471.7 423.2 1439.6 458.7 1392.2 473.4C1374.4 478.9 1350.5 481 1332.4 478.5Z" fill={color === 'white' ? '#FBFBFB' : '#5230FF'} data-sentry-element="path" data-sentry-source-file="appLogo.tsx" />
            <path d="M1046 478.4C1005.1 473.3 977.7 452.4 966.6 418C964.7 412.2 962.7 404 962.1 399.7C961.4 394.7 961 367.6 961 325.4V259H932H903V228.5V198H913.3C918.9 197.9 931.9 197.9 942.3 198H961V152.5V107H998H1035V152.5V197.9H1079.8C1104.4 197.9 1127.8 198 1131.8 198H1139V228.5V259H1087H1034.9L1035.2 324.8L1035.5 390.5L1038.1 396C1043.5 407.6 1053.1 413.4 1066.5 413.4C1075.6 413.4 1081.2 411.4 1087.4 406C1093.2 400.9 1096.1 394.2 1099.4 377.9L1099.9 375.4L1117.7 378.6C1158.1 386.1 1168.3 388 1168.6 388.3C1168.8 388.5 1168 393 1166.9 398.3C1160.2 431.1 1143 455 1116.5 468.1C1100.7 475.9 1090.3 478.2 1068 478.5C1057.8 478.7 1047.9 478.6 1046 478.4Z" fill={color === 'white' ? '#FBFBFB' : '#5230FF'} data-sentry-element="path" data-sentry-source-file="appLogo.tsx" />
            <path d="M1671.5 478.4C1663.9 477.5 1646.9 473.9 1640.2 471.6C1620.3 464.7 1602.5 453.6 1587.4 438.5C1559.1 410.3 1546 377.7 1546 335.5C1546 293.4 1558.9 261 1586.5 233.5C1598.9 221.1 1607.9 214.5 1622.5 207.2C1642.8 197 1660.7 192.9 1687.5 192.5C1709.7 192.1 1719.8 193.3 1737.4 198.6C1781.7 211.7 1817.5 247.6 1830.3 292C1839.4 323.4 1837 365.8 1824.5 395C1809.4 430.2 1777.8 459.4 1741.6 471.5C1726.7 476.5 1715.8 478.1 1695 478.5C1684.3 478.6 1673.7 478.6 1671.5 478.4ZM1711 409.9C1735 403.5 1753.1 384.5 1760.1 358.5C1762.7 348.8 1763.2 326.2 1761.1 316.5C1757.7 301 1748.5 284.8 1737.9 275.6C1731.7 270.3 1721.6 264.5 1714 262C1703.8 258.6 1684.1 258.1 1673 260.9C1659.7 264.2 1651 269.1 1640.9 279C1633.6 286.2 1631.6 289 1627.7 297C1622.1 308.6 1620.1 316.2 1619.3 330C1617.6 360.7 1628.9 386.1 1650.8 400.8C1667.7 412.1 1689.8 415.4 1711 409.9Z" fill={color === 'white' ? '#FBFBFB' : '#5230FF'} data-sentry-element="path" data-sentry-source-file="appLogo.tsx" />
            <path d="M1898 270V66H1935.5H1973V156.3V246.5L1976 241.2C1992.2 211.7 2022.2 194.1 2059 192.3C2104.5 190.2 2138.6 208.9 2156.1 245.5C2161.6 257.1 2164.6 268.4 2166 282.5C2166.5 288.1 2167 332.2 2167 383.3V474H2129.5H2092V388.2C2092 306.6 2091.9 301.9 2090.1 294.8C2084.8 274 2071.2 260.1 2051.8 255.5C2048.3 254.7 2040.9 254 2035.2 254C2026.8 254 2023.5 254.5 2017.4 256.5C2004.6 260.8 1995 268.1 1986.9 279.8C1980.5 288.9 1977.6 296 1975 308.8C1973.1 318.4 1973 322.2 1973 396.4V474H1935.5H1898V270Z" fill={color === 'white' ? '#FBFBFB' : '#5230FF'} data-sentry-element="path" data-sentry-source-file="appLogo.tsx" />
            <path d="M2244 336C2244 198.7 2244 198 2246 198C2247.1 198 2262 197.9 2279 197.9C2296.1 197.9 2312 198 2314.5 198H2319V336V474H2281.5H2244V336Z" fill={color === 'white' ? '#FBFBFB' : '#5230FF'} data-sentry-element="path" data-sentry-source-file="appLogo.tsx" />
            <path d="M2269.3 148.5C2255.3 144.7 2243.9 135.7 2237.9 123.5C2234.6 116.8 2234.5 116.1 2234.5 105.5C2234.5 94.7001 2234.6 94.2001 2238.3 86.8001C2245 73.1001 2256.8 64.4001 2273.1 61.3001C2280.8 59.7001 2281.9 59.7001 2289.4 61.3001C2306.4 64.8001 2319.1 74.7001 2325.5 89.5001C2328.2 95.7001 2328.7 111.4 2326.5 118.1C2319 141 2292.9 154.8 2269.3 148.5Z" fill={color === 'white' ? '#FBFBFB' : '#5230FF'} data-sentry-element="path" data-sentry-source-file="appLogo.tsx" />
            <path d="M36.0645 7.62903C40.2258 5.40968 46.1903 2.9129 49.2419 1.94194C53.6806 0.554839 105.835 0.13871 299.613 0C539.026 0 544.574 0 553.452 2.63548C558.445 4.16129 566.49 8.04516 571.345 11.3742C576.339 14.7032 582.442 19.8355 585.077 22.8871C587.713 25.9387 592.013 32.7355 594.51 38.1452C597.145 43.4161 599.781 51.3226 600.613 55.4839C601.584 60.4774 602 144.397 602 301.694C602 511.977 601.723 541.384 599.781 549.29C598.671 554.284 595.619 561.913 593.261 566.49C590.765 570.929 585.077 578.142 580.5 582.581C575.645 587.297 568.432 592.429 563.161 594.926C558.168 597.284 550.677 599.781 546.516 600.613C541.523 601.584 458.435 602 299.89 602C130.942 602 58.9516 601.584 53.8194 600.474C49.7968 599.642 42.4452 597.006 37.4516 594.648C31.6258 592.013 25.3839 587.435 19.9742 582.026C15.2581 577.31 9.9871 570.374 8.04516 566.629C6.10323 562.745 3.46774 556.226 2.21935 552.065C0.13871 545.129 0 521.41 0 299.89C0 68.9387 0.13871 55.0677 2.49677 47.4387C3.88387 43.1387 6.65806 36.7581 8.6 33.2903C10.5419 29.8226 15.8129 23.5806 20.2516 19.2806C24.829 14.9806 31.9032 9.70968 36.0645 7.62903Z" fill={color === 'white' ? '#FBFBFB' : '#5230FF'} data-sentry-element="path" data-sentry-source-file="appLogo.tsx" />
            <path ref={pathRef} d="M247.4 426.3C247.3 386 247.3 323.9 247.4 288.3L247.5 223.5L188.7 223.3L130 223L130.4 190.7C130.6 172.9 131.1 153.6 131.4 147.7L132 137H300.5H468.9L469.6 141C470 143.2 470.6 162.7 471 184.3L471.7 223.5H413.1H354.5V329.3C354.5 387.4 354.2 449.5 353.8 467.3L353.2 499.5H300.3H247.5L247.4 426.3Z" fill={color === 'white' ? '#5230FF' : '#FBFBFB'} data-sentry-element="path" data-sentry-source-file="appLogo.tsx" />
        </svg>;
}