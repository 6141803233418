const urlBase = '/api/v1'
const playerService = '/player-service'
const gamingService = '/game-service'
const paymentsService = '/payments-service'
const rewardService = '/rewards-service'

const urls = {
  users: `${playerService}${urlBase}/users`,
  logout: `${playerService}${urlBase}/logout`,
  players: {
    login: `${playerService}${urlBase}/authenticate`,
    register: `${playerService}${urlBase}/register`,
    phoneAvailable: `${playerService}${urlBase}/register/mobile-available`,
    aliasAvailable: `${playerService}${urlBase}/account/exists/alias`,
    emailAvailable: `${playerService}${urlBase}/account/exists`,
    userProfile: `${playerService}${urlBase}/account`,
    registerAccountEmail: `${playerService}${urlBase}/account/email`,
    updateProfile: `${playerService}${urlBase}/profile/account`,
    verify: `${playerService}${urlBase}/users/verify`,
    verifySMS: `${playerService}${urlBase}/register/resend-sms-code`,
    sendSMS: `${playerService}${urlBase}/register/send-sms-code`,
    verifySMSCode: `${playerService}${urlBase}/register/verify-sms-code`,
    activate: `${playerService}${urlBase}/activate`,
    initResetPassword: `${playerService}${urlBase}/account/reset-password/init`,
    finishResetPassword: `${playerService}${urlBase}/account/reset-password/finish`,
    isProfileVerified: `${playerService}${urlBase}/account/verify-info`,
    selfExclusionOptions: `${playerService}${urlBase}/self-exclusion/options`,
    setExclusionOption: `${playerService}${urlBase}/self-exclusion`,
    changePassword: `${playerService}${urlBase}/account/change-password`,
    tuohiLaunch: `${playerService}${urlBase}/login/launch`,
    tuohiGetToken: `${playerService}${urlBase}/login/success/{{loginId}}`,
    initVerifyPhoneEmail: `${playerService}${urlBase}/account/user-data`,
    verifyPhoneEmail: `${playerService}${urlBase}/account/verify-user-data`,
    reSendVerifyEmail: `${playerService}${urlBase}/register/email-verification`,
    reSendVerifySms: `${playerService}${urlBase}/register/email-verification`,
  },
  bonus: {
    getAllBonuses: `${rewardService}${urlBase}/bonuses/list/deposit`,
    getActiveBonus: `${rewardService}${urlBase}/bonuses/current-bonus`,
    getAcceptedBonuses: `${rewardService}${urlBase}/accepted-bonuses/deposit`,
    associateUserToBonuses: `${rewardService}${urlBase}/accepted-bonuses/associate`,
    activateUserBonus: `${rewardService}${urlBase}/accepted-bonuses/activate`,
    validateBonusCode: `${rewardService}${urlBase}/bonuses/validate`,
    cancelBonus: `${rewardService}${urlBase}/bonuses/cancel-bonus`,
    history: `${rewardService}${urlBase}/bonuses/history/deposit`,
    xpProgress: `${rewardService}${urlBase}/xpbonus/progress`,
    listTuohirulla: `${rewardService}${urlBase}/bonuses/list/wheels`,
    populateTuohirulla: `${rewardService}${urlBase}/wheels/populate/{{code}}`,
  },
  freeSpins: {
    getAvailableFreeSpins: `${rewardService}${urlBase}/bonuses/list/freespin`,
    getActiveFreeSpins: `${rewardService}${urlBase}/freespins/active`,
    getAcceptedFreeSpins: `${rewardService}${urlBase}/accepted-bonuses/freespin`,
    getHistory: `${rewardService}${urlBase}/bonuses/history/freespin`,
    getExchangeFreeSpins: `${rewardService}${urlBase}/exchanges/{{currencyToken}}`,
    exchangeFreeSpins: `${rewardService}${urlBase}/exchanges`,
    exchange: `${rewardService}${urlBase}/exchanges-transactions`,
    wageringLeft: `${rewardService}${urlBase}/user-bonus-progress/remaining-turnover`,
  },
  missions: {
    getAllMissions: `${rewardService}${urlBase}/bonuses/list/missions`,
    getActiveMissions: `${rewardService}${urlBase}/missions/progress`,
    getAcceptedMissions: `${rewardService}${urlBase}/accepted-bonuses/missions`,
    getHistory: `${rewardService}${urlBase}/bonuses/history/missions`,
  },
  cashback: {
    getAllCashback: `${rewardService}${urlBase}/bonuses/list/cashback`,
    getActiveCashback: `${rewardService}${urlBase}/bonuses/current-bonus`,
    getAcceptedCashback: `${rewardService}${urlBase}/accepted-bonuses/cashback`,
    getHistory: `${rewardService}${urlBase}/bonuses/history/cashback`,
  },
  countries: {
    getAll: `${playerService}${urlBase}/countries`,
    create: `${playerService}${urlBase}/countries`,
    update: `${playerService}${urlBase}/countries`,
    delete: `${playerService}${urlBase}/countries`,
    block: `${playerService}${urlBase}/countries/block`,
    unblock: `${playerService}${urlBase}/countries/unblock`,
  },
  states: {
    getAll: `${playerService}${urlBase}/states`,
    create: `${playerService}${urlBase}/states`,
    update: `${playerService}${urlBase}/states`,
    delete: `${playerService}${urlBase}/states`,
    block: `${playerService}${urlBase}/state/block`,
    unblock: `${playerService}${urlBase}/state/unblock`,
  },
  games: {
    getLobbies: `${gamingService}${urlBase}/lobby`,
    justLobbyInfo: `${gamingService}${urlBase}/lobby`,
    gamesCategory: `${gamingService}${urlBase}/category`,
    gamesProvider: `${gamingService}${urlBase}/games/per-provider`,
    gamesSupplier: `${gamingService}${urlBase}/games/per-supplier`,
    search: `${gamingService}${urlBase}/games`,
    getCategories: `${gamingService}${urlBase}/categories`,
    gameInfo: `${gamingService}${urlBase}/games/game-info`,
    lastPlayed: `${gamingService}${urlBase}/games/last-played`,
    suppliers: `${gamingService}${urlBase}/games/suppliers`,
  },
  wallet: {
    getBalance: `${playerService}${urlBase}/account/balance`,
    getPaymentFrameUrl: `${paymentsService}/cashier/launch`,
    getTransactions: `${playerService}${urlBase}/transactions/cashier`,
    getBetTransactions: `${playerService}${urlBase}/transactions/bet`,
    getPaymentMethods: `${paymentsService}/cashier/methods`,
    getTransactionStatus: `${paymentsService}/cashier/status`,
    getBoundaries: `${paymentsService}/cashier/boundaries`,
    getWageringRequirements: `${paymentsService}/cashier/wagering-requirements`,
  },
  seo: {
    getPage: `${playerService}${urlBase}/seo-settings`,
    updatePage: `${playerService}${urlBase}/seo-settings`,
    createPage: `${playerService}${urlBase}/seo-settings`,
    gameSeoInfo: `${gamingService}${urlBase}/games/game-info`,
  },
  currencies: {
    getAllCurrencies: `${playerService}${urlBase}/currencies`,
  },
  banners: {
    getAllBanners: `${playerService}${urlBase}/banners`,
    getBannerByName: `${playerService}${urlBase}/banners/name`,
  },
  messages: {
    getAllMessages: `${playerService}${urlBase}/site-messages`,
  },
  support: {
    submitContactForm: `${playerService}${urlBase}/contact-forms`,
  },
  lobbies: {
    getAll: `${gamingService}${urlBase}/lobbies`,
  },
  featureFlag: {
    getAll: `${playerService}${urlBase}/feature-flags`,
  },
  depositLimit: {
    get: `${playerService}${urlBase}/deposit-limit`,
    create: `${playerService}${urlBase}/deposit-limit`,
    update: `${playerService}${urlBase}/deposit-limit`,
  },
  translationsBO: {
    // translations for common things like the BO
    getAll: `${playerService}${urlBase}/translations`,
    create: `${playerService}${urlBase}/translations`,
    update: `${playerService}${urlBase}/translations`,
    delete: `${playerService}${urlBase}/translations`,
  },
  campaigns: {
    getAll: `${playerService}${urlBase}/campaigns`,
    getByExternalName: `${playerService}${urlBase}/campaigns/external-name/{{externalName}}`,
  },
}

export default urls

export const payloadCMSUrl = {
  globals: {
    siteConfig: '/globals/site-config',
  },
  collections: {
    posts: '/posts',
  },
}
