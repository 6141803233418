import Typography from 'components/uiKit/typography';
import React, { ChangeEventHandler, HTMLInputTypeAttribute, useState } from 'react';
import { globalData } from 'pages/_app';
import Grid from 'components/uiKit/grid';
import { typographySize } from 'components/uiKit/typography/types';
import styles from './input.group.module.scss';
export interface uiKitInputProps {
  label: string | undefined | any;
  value?: string | ReadonlyArray<string> | number | undefined | boolean;
  name: string;
  id: string;
  inputType?: HTMLTextAreaElement | 'textarea';
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLTextAreaElement>;
  status?: 'error' | 'success' | 'warning' | 'info' | '';
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;
  feedback?: React.ReactNode;
  styles?: React.CSSProperties;
  noEditTranslation?: boolean;
  fontSize?: typographySize | Array<typographySize>;
}
export default function TextAreaGroup(props: uiKitInputProps) {
  const {
    label,
    value,
    name,
    id,
    inputType: inputTypeProps,
    onChange,
    status,
    inputProps,
    feedback,
    styles: stylesProps,
    noEditTranslation,
    fontSize
  } = props;
  const [inputType, setInputType] = useState(inputTypeProps || 'text');
  const {
    editTranslations
  } = globalData;
  function handleClick(e: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) {
    if (inputType === 'date' || inputProps?.type === 'date') {
      const {
        target
      }: any = e as any; // this prevent eslint warn
      target.showPicker();
    }
    if (editTranslations && inputProps?.onClick) {
      if (e?.target === e?.currentTarget) {
        inputProps?.onClick(e);
      }
    } else if (inputProps?.onClick) {
      inputProps?.onClick(e);
    }
  }
  return <>
            {editTranslations && !noEditTranslation ? <Grid>
                            <Grid className={styles['animated-label']}>
                                {typeof label === 'string' ? <Typography translateGroup="input-group-label" translateKey={label} size="sm" /> : label}
                            </Grid>
                            <textarea value={value as any} onChange={onChange} rows={6} name={name} id={id} {...inputProps} onClick={e => handleClick(e)} ref={inputProps?.ref as React.Ref<HTMLTextAreaElement>} />
                            <div data-status={status} data-font-size={fontSize || 'sm'} className={styles['input-group-wrapper']} style={{
        ...stylesProps
      }}>

                                {feedback && <div className={styles.feedback}>
                                            <Typography size="xsm">
                                                {feedback}
                                            </Typography>
                                        </div>}
                            </div>
                        </Grid> : <div data-status={status} data-font-size={fontSize || 'sm'} className={styles['input-group-wrapper']} style={{
      ...stylesProps
    }}>
                            <label>
                                <textarea value={value as any} rows={6} onChange={onChange} placeholder={label} name={name} id={id} style={{
          width: '100%',
          ...inputProps?.style
        }} {...inputProps} onClick={e => handleClick(e)} />
                                <div className={styles['animated-label']}>
                                    {typeof label === 'string' ? <Typography translateGroup="input-group-label" translateKey={label} size="sm" /> : label}
                                </div>
                            </label>
                            {feedback && <div className={styles.feedback}>
                                        <Typography size="xsm">
                                            {feedback}
                                        </Typography>
                                    </div>}
                        </div>}

        </>;
}