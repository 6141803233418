import { useContext } from 'react';
import GlobalContext from 'context/global';
import FacebookIcon from 'assets/icons/socialIcons/facebookIcon';
import Instagram from 'assets/icons/socialIcons/instagram';
import TikTokIcon from 'assets/icons/socialIcons/tikTokIcon';
import XIcon from 'assets/icons/socialIcons/xIcon';
import Grid from 'components/uiKit/grid';
import Link from 'components/customLink';
import styles from './styles.module.scss';
export function SocialMedia() {
  const {
    appType
  } = useContext(GlobalContext);
  return <Grid gap="0.5rem" verticalAlgin="center" horizontalAlgin="center" wrap="nowrap" responsiveWidth={{
    sm: 100,
    md: 'calc(50% - 1rem)'
  }} className={styles.socialMedia} data-sentry-element="Grid" data-sentry-component="SocialMedia" data-sentry-source-file="socialMedia.tsx">
      <Grid wrap="nowrap" gap="1.5rem" responsiveWidth={{
      sm: '50%'
    }} horizontalAlgin={appType === 'mobile' ? 'center' : undefined} data-sentry-element="Grid" data-sentry-source-file="socialMedia.tsx">
        <Link href="https://www.instagram.com/tuohi_kasino?igsh=MTV1ZWM4b3pxa252Ng%3D%3D&utm_source=qr" passHref target="_blank" aria-label="instagram" data-sentry-element="Link" data-sentry-source-file="socialMedia.tsx">
          <Instagram data-sentry-element="Instagram" data-sentry-source-file="socialMedia.tsx" />
        </Link>
      </Grid>
      <Grid wrap="nowrap" gap="1.5rem" responsiveWidth={{
      sm: '50%'
    }} data-sentry-element="Grid" data-sentry-source-file="socialMedia.tsx">
        <Link href="https://www.facebook.com/profile.php?id=61564899427502" passHref target="_blank" aria-label="facebook" data-sentry-element="Link" data-sentry-source-file="socialMedia.tsx">
          <FacebookIcon data-sentry-element="FacebookIcon" data-sentry-source-file="socialMedia.tsx" />
        </Link>
      </Grid>
      <Grid wrap="nowrap" responsiveWidth={{
      sm: '50%'
    }} gap="1.5rem" data-sentry-element="Grid" data-sentry-source-file="socialMedia.tsx">
        <Link href="https://x.com/" passHref target="_blank" hidden aria-label="x" data-sentry-element="Link" data-sentry-source-file="socialMedia.tsx">
          <XIcon data-sentry-element="XIcon" data-sentry-source-file="socialMedia.tsx" />
        </Link>
      </Grid>
      <Grid wrap="nowrap" responsiveWidth={{
      sm: '50%'
    }} gap="1.5rem" data-sentry-element="Grid" data-sentry-source-file="socialMedia.tsx">
        <Link href="https://www.tiktok.com/" passHref target="_blank" hidden aria-label="tiktok" data-sentry-element="Link" data-sentry-source-file="socialMedia.tsx">
          <TikTokIcon data-sentry-element="TikTokIcon" data-sentry-source-file="socialMedia.tsx" />
        </Link>
      </Grid>
    </Grid>;
}