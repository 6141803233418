import { ReactNode } from 'react'
import { toast, ToastOptions } from 'react-toastify'

export function toastSuccess(content: ReactNode, options?: ToastOptions) {
    const settings: ToastOptions = {
        ...options,
    }
    return toast.success(content, settings)
}

export function toastWarning(content: ReactNode, options?: ToastOptions) {
    const settings: ToastOptions = {
        ...options,
    }
    return toast.warning(content, settings)
}

export function toastInfo(content: ReactNode, options?: ToastOptions) {
    const settings: ToastOptions = {
        ...options,
    }
    return toast.info(content, settings)
}

export function toastError(content: ReactNode, options?: ToastOptions) {
    const settings: ToastOptions = {
        ...options,
    }
    return toast.error(content, settings)
}

export function toastLevelUp(content: ReactNode, options?: ToastOptions) {
  const settings: ToastOptions = {
    position: 'top-center',
    autoClose: 5000,
    closeButton: false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: 'light',
    className: '!rounded-2xl !bg-primary border border-[var(--toast-container-border)] xs:!w-full sm:!max-w-[393px] h-[90px] !bg-starburst',
    bodyClassName: '[&>div:last-child]:!bg-transparent [&>div:last-child]:!p-0 !py-0 !px-2 bg-#703E2D',
    ...options,
  }
  return toast(content, settings)
}

export function toastPopup(content: ReactNode, options?: ToastOptions) {
  const settings: ToastOptions = {
    position: 'top-center',
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: 'light',
    className: 'Toastify__toast-popup !rounded-[10px] xs:!w-[calc(100%-32px)] xs:m-4 md:!w-[320px] md:m-0 !p-3',
    bodyClassName: '[&>div:last-child]:!bg-transparent [&>div:last-child]:!p-0 !py-0 bg-#703E2D',
    ...options,
  }
  return toast(content, settings)
}
