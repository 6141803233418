import React, { useEffect, useState } from 'react';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import Button from 'components/uiKit/buttons';
import Card from '../card';
import styles from './styles.module.scss';
export default function CookieNotification({
  onAccept
}: {
  onAccept: () => void;
}) {
  const [render, setRender] = useState(false);
  useEffect(() => {
    const response = localStorage.getItem('cookie-notification-response');
    if (response) {
      setRender(false);
    } else {
      setRender(true);
    }
  }, []);
  function acceptCookies() {
    localStorage.setItem('cookie-notification-response', 'true');
    setRender(false);
    onAccept();
  }
  if (!render) {
    return <></>;
  }
  return <div className={styles.cookieNotification} data-sentry-component="CookieNotification" data-sentry-source-file="index.tsx">
            <div className={styles.wrapper}>
                <Typography translateGroup="cookie-notification-card" translateKey="cookie-notification-text" style={{
        flex: 1
      }} data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
                <Button id="accept-cookie-cta" color="yellow" onClick={() => acceptCookies()} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                    <Typography translateGroup="cookie-notification-card" translateKey="HYVÄKSY" data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
                </Button>
            </div>
        </div>;
}