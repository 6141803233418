import { useState, useEffect } from 'react';
const handleChatOpen = () => {
  const chatWidget: any = document.querySelector('.zendesk-chat-widget');
  const launcher = document.getElementById('launcher');
  if (chatWidget) chatWidget.style.display = 'block';
  if (launcher) launcher.style.display = 'block';
};
export const handleChatClose = () => {
  const chatWidget: any = document.querySelector('.zendesk-chat-widget');
  const launcher = document.getElementById('launcher');
  if (chatWidget) chatWidget.style.display = 'none';
  if (launcher) launcher.style.display = 'none';
};
const useZendeskChatVisibility = () => {
  const [isZendeskLoaded, setIsZendeskLoaded] = useState(false);
  useEffect(() => {
    if (window.zE) {
      setIsZendeskLoaded(true);
    } else {
      // Listen for the Zendesk script load event
      const handleZendeskLoad = () => setIsZendeskLoaded(true);
      window.addEventListener('zendeskLoaded', handleZendeskLoad);

      // Cleanup event listener on unmount
      return () => {
        window.removeEventListener('zendeskLoaded', handleZendeskLoad);
      };
    }
    return () => {};
  }, []);
  useEffect(() => {
    if (isZendeskLoaded) {
      // Attach event listeners only after Zendesk is loaded
      window.zE('messenger:on', 'open', handleChatOpen);
      window.zE('messenger:on', 'close', handleChatClose);

      // Cleanup listeners on unmount
      return () => {
        window.zE('messenger:off', 'open', handleChatOpen);
        window.zE('messenger:off', 'close', handleChatClose);
      };
    }
    return () => {};
  }, [isZendeskLoaded]);
};
export default useZendeskChatVisibility;