import Link from 'components/customLink';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import GlobalContext from 'context/global';
import { useContext } from 'react';
import { useProfile } from 'utils/customHooks/useProfile';
import styles from './styles.module.scss';
export function LinksContacts() {
  const {
    appType,
    handlerOpenZendesk
  } = useContext(GlobalContext);
  const {
    profile
  } = useProfile();
  const name = profile ? [profile.firstName, profile.lastName].join(' ') : '';
  return <Grid gap="1.5rem" responsiveWidth={{
    sm: 100,
    md: 'calc(50% - 1rem)'
  }} className={styles.linksContacts} data-sentry-element="Grid" data-sentry-component="LinksContacts" data-sentry-source-file="linksContacts.tsx">
      <Grid responsiveWidth={{
      sm: 'calc(50% - 1.5rem)'
    }} gap="1.5rem" data-sentry-element="Grid" data-sentry-source-file="linksContacts.tsx">
        <Grid horizontalAlgin={appType === 'mobile' ? 'center' : undefined} data-sentry-element="Grid" data-sentry-source-file="linksContacts.tsx">
          <Link href="/info/ukk" data-sentry-element="Link" data-sentry-source-file="linksContacts.tsx">
            <Typography translateGroup="footer" translateKey="footer-link-1" data-sentry-element="Typography" data-sentry-source-file="linksContacts.tsx" />
          </Link>
        </Grid>
        <Grid horizontalAlgin={appType === 'mobile' ? 'center' : undefined} data-sentry-element="Grid" data-sentry-source-file="linksContacts.tsx">
          <Grid width="fit-content" style={{
          cursor: 'pointer'
        }} onClick={() => handlerOpenZendesk(name)} data-sentry-element="Grid" data-sentry-source-file="linksContacts.tsx">
            <Typography translateGroup="footer" translateKey="footer-link-2" data-sentry-element="Typography" data-sentry-source-file="linksContacts.tsx" />
          </Grid>
        </Grid>
      </Grid>
      <Grid responsiveWidth={{
      sm: 'calc(50% - 1.5rem)'
    }} gap="1.5rem" data-sentry-element="Grid" data-sentry-source-file="linksContacts.tsx">
        <Grid horizontalAlgin={appType === 'mobile' ? 'center' : undefined} data-sentry-element="Grid" data-sentry-source-file="linksContacts.tsx">
          <Link href="/info/affiliaatit" data-sentry-element="Link" data-sentry-source-file="linksContacts.tsx">
            <Typography translateGroup="footer" translateKey="footer-link-3" data-sentry-element="Typography" data-sentry-source-file="linksContacts.tsx" />
          </Link>
        </Grid>
        <Grid horizontalAlgin={appType === 'mobile' ? 'center' : undefined} data-sentry-element="Grid" data-sentry-source-file="linksContacts.tsx">
          <Link href="/info/policy-cookies" data-sentry-element="Link" data-sentry-source-file="linksContacts.tsx">
            <Typography translateGroup="footer" translateKey="footer-link-8" data-sentry-element="Typography" data-sentry-source-file="linksContacts.tsx" />
          </Link>
        </Grid>
      </Grid>
    </Grid>;
}