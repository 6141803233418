import React, { useEffect, useState } from 'react';
import { toastError, toastSuccess } from 'utils/functions/notifications';
import Grid from 'components/uiKit/grid';
import InputGroup from 'components/uiKit/inputs/inputGroup';
import Typography from 'components/uiKit/typography';
import Toggle from 'components/uiKit/inputs/Toggle';
import Button from 'components/uiKit/buttons';
import Loading from 'assets/loading';
import { useRouter } from 'next/router';
import EditorV2 from 'components/uiKit/inputs/EditorV2';
import { TranslationItemI, translationTokenName } from '..';
interface TranslationFormI {
  currentTranslation?: TranslationItemI;
}
export default function TranslationForm({
  currentTranslation
}: TranslationFormI) {
  const [loading, setLoading] = useState(false);
  const locale = 'fi-FI';
  const client = 'FE';
  const [translation, setTranslation] = useState<TranslationItemI>({
    key: '',
    value: '',
    client: '',
    group: '',
    isHtml: false,
    paths: [],
    ...currentTranslation,
    locale: 'fi-FI'
  });
  async function fetchCurrentTranslation() {
    setLoading(true);
    try {
      const url = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/player-service/api/v1/translations?filterExp=client$eq=${client}[AND]locale$eq=${locale}[and]group$eq=${translation?.group}[and]key$eq=${translation?.key}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem(translationTokenName)}`
        }
      });
      setLoading(false);
      if (response.ok) {
        const data = await response.json();
        if (data?.length > 0) {
          setTranslation(data?.[0]);
        }
      } else {
        throw new Error('Fetch failed');
      }
    } catch (error) {
      setLoading(false);
      toastError('Something went wrong.');
    }
  }
  useEffect(() => {
    fetchCurrentTranslation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const method = translation?.id ? 'PUT' : 'POST';
    const url = '/api/manage-translations';
    setLoading(true);
    try {
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem(translationTokenName)}`
        },
        body: JSON.stringify(translation)
      });
      if (response.ok) {
        setLoading(false);
        const data = await response.json();
        setTranslation(old => ({
          ...old,
          ...data
        }));
        toastSuccess(method === 'PUT' ? 'Translation updated successfully' : 'Translation created successfully');
        // Handle success
      } else {
        setLoading(false);
        toastError('Something went wrong.');
      }
    } catch (error) {
      setLoading(false);
      toastError('Something went wrong.');
    }
  };
  return <Grid padding={['p-4']} style={{
    width: '900px',
    maxWidth: 'calc(100dvw - 2rem)'
  }} data-sentry-element="Grid" data-sentry-component="TranslationForm" data-sentry-source-file="form.tsx">
            <form lang="fi" onSubmit={handleSubmit}>
                <Grid data-sentry-element="Grid" data-sentry-source-file="form.tsx">
                    <Grid width={100} data-sentry-element="Grid" data-sentry-source-file="form.tsx">
                        <h3>
                            <b> EDIT TRANSLATION CONTENT</b>
                        </h3>
                    </Grid>
                    <Grid width={100} margin="mb-1" data-sentry-element="Grid" data-sentry-source-file="form.tsx">
                        <h4>
                            Translation Details:
                        </h4>
                    </Grid>
                    <Grid width={100} gap="10px" margin="mb-3" data-sentry-element="Grid" data-sentry-source-file="form.tsx">
                        <b>
                            GROUP:
                        </b>
                        <span>
                            {translation?.group}
                        </span>
                        <b>
                            KEY:
                        </b>
                        <span>
                            {translation?.key}
                        </span>
                        <b>
                            LOCALE:
                        </b>
                        <span>
                            {translation?.locale}
                        </span>
                    </Grid>
                </Grid>

                <Grid style={{
        opacity: loading ? 0.5 : 1,
        pointerEvents: loading ? 'none' : 'auto'
      }} data-sentry-element="Grid" data-sentry-source-file="form.tsx">
                    <Grid padding={['pt-3', 'pb-3']} data-sentry-element="Grid" data-sentry-source-file="form.tsx">
                        <div>
                            <label htmlFor="isHtmlTranslationToggle">is HTML? (Rich text)</label>
                            <input type="checkbox" name="isHtmlTranslationToggle" id="isHtmlTranslationToggle" checked={!!translation?.isHtml} onChange={() => setTranslation(old => ({
              ...old,
              isHtml: !old.isHtml
            }))} />
                        </div>
                    </Grid>

                    <Grid padding={['pt-3']} style={{
          color: '#000'
        }} data-sentry-element="Grid" data-sentry-source-file="form.tsx">
                        {translation?.isHtml ? <EditorV2 id="value" name="value" value={translation?.value as string} onChange={({
            target
          }) => setTranslation(old => ({
            ...old,
            value: target.value
          }))} label="value" useNativeElements /> : <Grid>
                                        <label htmlFor="value">
                                            value
                                        </label>
                                        <input type="text" id="value" name="value" value={translation?.value} onChange={({
              target
            }) => setTranslation(old => ({
              ...old,
              value: target.value
            }))} style={{
              width: '100%',
              padding: '0.5rem'
            }} />
                                    </Grid>}

                    </Grid>
                </Grid>
                <Grid padding={['pt-5', 'pb-3']} horizontalAlgin="flex-end" data-sentry-element="Grid" data-sentry-source-file="form.tsx">
                    <button id="translation-login-cta" type="submit">
                        {loading && <Loading />}
                        save
                    </button>
                </Grid>
            </form>
        </Grid>;
}