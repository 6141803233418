import React from 'react';
export default function FacebookIcon(props: React.SVGProps<SVGSVGElement>) {
  return <svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="FacebookIcon" data-sentry-source-file="facebookIcon.tsx">
            <g clipPath="url(#clip0_180_464)" data-sentry-element="g" data-sentry-source-file="facebookIcon.tsx">
                <path d="M27.9996 14.0855C27.9996 6.30505 21.7311 -0.00195312 13.9996 -0.00195312C6.26459 -0.000203125 -0.00390625 6.30505 -0.00390625 14.0873C-0.00390625 21.117 5.11659 26.9445 11.8086 28.0015V18.1578H8.25609V14.0873H11.8121V10.981C11.8121 7.4513 13.9033 5.5018 17.1006 5.5018C18.6336 5.5018 20.2348 5.77655 20.2348 5.77655V9.24155H18.4691C16.7313 9.24155 16.1888 10.3283 16.1888 11.443V14.0855H20.0703L19.4508 18.156H16.1871V27.9998C22.8791 26.9428 27.9996 21.1153 27.9996 14.0855Z" fill="white" data-sentry-element="path" data-sentry-source-file="facebookIcon.tsx" />
            </g>
            <defs data-sentry-element="defs" data-sentry-source-file="facebookIcon.tsx">
                <clipPath id="clip0_180_464" data-sentry-element="clipPath" data-sentry-source-file="facebookIcon.tsx">
                    <rect width={28} height={28} fill="white" data-sentry-element="rect" data-sentry-source-file="facebookIcon.tsx" />
                </clipPath>
            </defs>
        </svg>;
}