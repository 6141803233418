import {
  loginData,
  mobileDTO,
  userRegisterEmailInterface,
  userRegisterInterface,
} from 'utils/globalTypes/users'
import { getSessionStorage } from 'utils/functions/sessionStorate'
import { api } from '../..'
import urls from '../urls'

export async function login(dataForm: loginData) {
  const response = await api.post(urls.players.login, {
    username: dataForm.email,
    password: dataForm.password,
    rememberMe: false,
  })
  return response
}

export async function getProfile() {
  const { data } = await api.get(urls.players.userProfile)
  return data
}

export async function isProfileVerified() {
  const { data } = await api.get(urls.players.isProfileVerified)
  return data || false
}
export async function reSendEmailVerification() {
  return api.put(urls.players.reSendVerifyEmail)
}
export async function reSendSmsVerification() {
  return api.put(urls.players.verifySMS)
}

export async function updateProfile(data: any) {
  return api.post(urls.players.updateProfile, data)
}

export async function registerUser(dataForm: userRegisterInterface) {
  return api.post(urls.players.register, dataForm)
}

export async function registerUserEmail(dataForm: userRegisterEmailInterface) {
  return api.post(urls.players.registerAccountEmail, dataForm)
}

export async function verifyNumberAvailable(form: mobileDTO) {
  const response = await api.put(urls.players.phoneAvailable, {
    ...form,
    mobileNumber: form.mobileNumber.replace(/\D/g, ''),
  })
  return response?.data
}
export async function verifyNumberSMS(form: mobileDTO) {
  const response = await api.put(`${urls.players.verifySMS}`, {
    ...form,
    mobileNumber: form.mobileNumber.replace(/\D/g, ''),
  })
  return response?.status
}
export async function sendNumberSMS(form: mobileDTO) {
  const response = await api.put(`${urls.players.sendSMS}`, {
    ...form,
    mobileNumber: form.mobileNumber.replace(/\D/g, ''),
  })
  return response?.status
}
export async function verifySMSCode(code: string, form: mobileDTO) {
  const response = await api.put(`${urls.players.verifySMSCode}/${code}`, {
    ...form,
    mobileNumber: form.mobileNumber.replace(/\D/g, ''),
  })
  return response?.data
}

export async function verifyEmailAvailable(email: string) {
  const response = await api
    .get(`${urls.players.emailAvailable}/${email}`)
    .catch((error) => ({ data: true }))
  return !response?.data
}
export async function verifyAliasAvailable(alias: string) {
  const response = await api
    .get(`${urls.players.aliasAvailable}/${alias}`)
    .catch((error) => ({ data: true }))
  return !response?.data
}
export async function activateAccount(key: string) {
  return api.get(`${urls.players.activate}`, { params: { key } })
}

interface EmailPhoneValidationInterface {
  email: string,
  mobilePrefix: string,
  mobileNumber: string,
}
export function initEmailPhoneValidation(props: EmailPhoneValidationInterface) {
  return api.put(urls.players.initVerifyPhoneEmail, props)
}

interface VerifyEmailPhoneValidationInterface {
  email: string,
  mobilePrefix: string,
  mobileNumber: string,
  emailCode: string,
  phoneNumberCode: string,
}
export function verifyEmailPhoneValidation(props: VerifyEmailPhoneValidationInterface) {
  return api.put(urls.players.verifyPhoneEmail, props)
}

export async function initResetPassword(mail: string) {
  const response = await api
    .post(urls.players.initResetPassword, { mail })
    .catch((error) => error?.response)
  if (response.status < 300) {
    return response
  }
  return response?.data
}

export interface resetPasswordInterface {
  key: string;
  newPassword: string;
}

export async function finishResetPassword(form: resetPasswordInterface) {
  const response = await api
    .post(urls.players.finishResetPassword, form)
    .catch((error) => error?.response)
  if (response.status < 300) {
    return response
  }
  return response?.data
}

export async function getSelfExclusionOptions() {
  const { data } = await api.get(urls.players.selfExclusionOptions)
  return data
}
export async function postSelfExclusionOption(option: string) {
  const response = await api.post(urls.players.setExclusionOption, {
    period: option,
  })
  return response
}

export async function changePassword(oldPassword: string, newPassword: string) {
  const response = await api.post(urls.players.changePassword, {
    currentPassword: oldPassword,
    newPassword,
  })
  return response
}

interface tuohiLoginI {
  depositAmount: number;
}
export async function tuohiLaunchLogin(body?: tuohiLoginI) {
  const btag = getSessionStorage('tuohi-btag')
  const response = await api.post(urls.players.tuohiLaunch, body, { params: btag ? { btag } : {} })
  sessionStorage.setItem('banner-origin', body ? 'deposit' : 'login')
  return response.data
}

export async function tuohiValidateLogin(id: string) {
  const response = await api.get(
    urls.players.tuohiGetToken.replace('{{loginId}}', id),
  )
  return response
}
