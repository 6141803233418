import Loading from 'assets/loading';
import Button from 'components/uiKit/buttons';
import Grid from 'components/uiKit/grid';
import InputGroup from 'components/uiKit/inputs/inputGroup';
import Typography from 'components/uiKit/typography';
import React, { useState } from 'react';
import { toastError, toastSuccess } from 'utils/functions/notifications';
import { translationTokenName } from '..';
interface TranslationLoginI {
  onLogin: () => void;
}
export default function LoginTranslationForm({
  onLogin
}: TranslationLoginI) {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/player-service/api/v1/authenticate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username,
          password,
          rememberMe: false
        })
      });
      if (response.ok) {
        const data = await response.json();
        sessionStorage.setItem(translationTokenName, data?.id_token);
        setLoading(false);
        onLogin();
        toastSuccess('login successful');
      } else {
        toastError('login failed');
        setLoading(false);
        throw new Error('Login failed');
      }
    } catch (error) {
      toastError('login failed');
      setLoading(false);
    }
  };
  return <Grid width="500px" style={{
    maxWidth: 'calc(100vw - 2rem)'
  }} gap="2rem" padding={['p-3']} data-sentry-element="Grid" data-sentry-component="LoginTranslationForm" data-sentry-source-file="login.tsx">
            <Grid gap="0.25rem" data-sentry-element="Grid" data-sentry-source-file="login.tsx">
                <Grid data-sentry-element="Grid" data-sentry-source-file="login.tsx">
                    <Typography weight={600} data-sentry-element="Typography" data-sentry-source-file="login.tsx">
                        Translation Edit Login
                    </Typography>
                </Grid>
                <Grid data-sentry-element="Grid" data-sentry-source-file="login.tsx">
                    <Typography size="sm" data-sentry-element="Typography" data-sentry-source-file="login.tsx">
                        This login is the same used at BO
                    </Typography>
                </Grid>

            </Grid>
            <form lang="fi" id="translation-edit-login-form" onSubmit={handleSubmit} style={{
      width: '100%'
    }}>
                <Grid gap="1rem" data-sentry-element="Grid" data-sentry-source-file="login.tsx">
                    <Grid data-sentry-element="Grid" data-sentry-source-file="login.tsx">
                        <InputGroup id="translation-edit-login-form-username" name="username" noEditTranslation label={<Typography weight={600}>
                                    Username
                                </Typography>} value={username} onChange={({
            target
          }) => {
            setUsername(target.value);
          }} data-sentry-element="InputGroup" data-sentry-source-file="login.tsx" />
                    </Grid>
                    <Grid data-sentry-element="Grid" data-sentry-source-file="login.tsx">
                        <InputGroup id="translation-edit-login-form-password" noEditTranslation name="password" label={<Typography weight={600}>
                                    Password
                                </Typography>} value={password} inputType="password" onChange={({
            target
          }) => {
            setPassword(target.value);
          }} data-sentry-element="InputGroup" data-sentry-source-file="login.tsx" />
                    </Grid>
                    <Grid padding={['pt-3']} data-sentry-element="Grid" data-sentry-source-file="login.tsx">
                        <Button id="translation-login-cta" type="submit" data-sentry-element="Button" data-sentry-source-file="login.tsx">
                            {loading && <Loading />}
                            Login
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Grid>;
}