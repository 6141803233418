import { useContext } from 'react';
import GlobalContext from 'context/global';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import Link from 'components/customLink';
import styles from './styles.module.scss';
export function LinksStandards() {
  const {
    appType
  } = useContext(GlobalContext);
  return <Grid gap="1.5rem" responsiveWidth={{
    sm: 100,
    md: 'calc(50% - 1rem)'
  }} className={styles.linksStandards} data-sentry-element="Grid" data-sentry-component="LinksStandards" data-sentry-source-file="linksStandards.tsx">
      <Grid horizontalAlgin={appType === 'mobile' ? 'center' : undefined} data-sentry-element="Grid" data-sentry-source-file="linksStandards.tsx">
        <Link href="/info/saannot-ja-ehdot" data-sentry-element="Link" data-sentry-source-file="linksStandards.tsx">
          <Typography translateGroup="footer" translateKey="footer-link-4" data-sentry-element="Typography" data-sentry-source-file="linksStandards.tsx" />
        </Link>
      </Grid>
      <Grid horizontalAlgin={appType === 'mobile' ? 'center' : undefined} data-sentry-element="Grid" data-sentry-source-file="linksStandards.tsx">
        <Link href="/info/tietosuojakaytanto" data-sentry-element="Link" data-sentry-source-file="linksStandards.tsx">
          <Typography translateGroup="footer" translateKey="footer-link-5" data-sentry-element="Typography" data-sentry-source-file="linksStandards.tsx" />
        </Link>
      </Grid>
      <Grid horizontalAlgin={appType === 'mobile' ? 'center' : undefined} data-sentry-element="Grid" data-sentry-source-file="linksStandards.tsx">
        <Link href="/info/vastuullinen-pelaaminen" data-sentry-element="Link" data-sentry-source-file="linksStandards.tsx">
          <Typography translateGroup="footer" translateKey="footer-link-6" data-sentry-element="Typography" data-sentry-source-file="linksStandards.tsx" />
        </Link>
      </Grid>
      <Grid horizontalAlgin={appType === 'mobile' ? 'center' : undefined} data-sentry-element="Grid" data-sentry-source-file="linksStandards.tsx">
        <Link href="/info/about" data-sentry-element="Link" data-sentry-source-file="linksStandards.tsx">
          <Typography translateGroup="footer" translateKey="footer-link-7" data-sentry-element="Typography" data-sentry-source-file="linksStandards.tsx" />
        </Link>
      </Grid>
    </Grid>;
}