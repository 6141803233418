/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-danger */
import { useRouter } from 'next/router';
import { globalData } from 'pages/_app';
import React from 'react';
import EditTranslation from './editorTranslation';
export interface Replaces {
  code: string;
  value: any;
}
interface Props {
  group: string;
  key: string;
  replaces?: Array<Replaces>;
  returnDefault?: 'translateKey' | 'nothing' | 'defaultContent';
  defaultContent?: any;
  data?: any;
}
function checkReplace(textPure: any, replaces: Array<Replaces>) {
  let textS = textPure || '';
  if (replaces?.length && textPure && typeof textPure === 'string') {
    replaces.map((r: Replaces) => {
      textS = textS?.replace(r.code, r.value);
      return textS || textPure;
    });
  }
  return textS || textPure;
}
function returnText(translations: any, group: any, textCode: any, locale: any) {
  const textFromLocale = translations?.length && translations?.find((t: any) => t?.group?.toLowerCase() === group?.toLowerCase() && t.key?.toLowerCase() === textCode?.toLowerCase() && t.locale?.toLowerCase() === locale?.toLowerCase());
  if (textFromLocale) {
    return textFromLocale;
  }
  const textFromDefaultLocale = translations?.length && translations?.find((t: any) => t?.group?.toLowerCase() === group?.toLowerCase() && t.key?.toLowerCase() === textCode?.toLowerCase() && t.locale === 'fi-FI');
  if (textFromDefaultLocale) {
    return textFromDefaultLocale;
  }
  return {};
}
export function textTranslated(props: Props) {
  const {
    group,
    key,
    replaces,
    returnDefault,
    data,
    defaultContent
  } = props;
  const {
    translations,
    editTranslations
  } = globalData;
  const {
    locale
  } = useRouter();
  const text: any = returnText(data?.length ? data : translations, group, key, locale) || {};
  if (editTranslations) {
    return <>
                {text ? text.is_html || text.isHTML || text.isHtml ? <section dangerouslySetInnerHTML={{
        __html: checkReplace(text.value || key, replaces as Array<Replaces>)
      }} /> : checkReplace(text.value || key, replaces as Array<Replaces>) : key}
                <EditTranslation replaces={replaces} currentTranslation={{
        ...text,
        group,
        key,
        isHtml: text?.is_html || text?.isHTML || text.isHtml,
        locale: locale as string,
        client: 'FE'
      }} />
            </>;
  }
  const returnDefaultValue = returnDefault === 'nothing' ? '' : returnDefault === 'defaultContent' ? defaultContent : key;
  return text ? text.is_html || text.isHTML || text.isHtml ? <section dangerouslySetInnerHTML={{
    __html: checkReplace(text.value || returnDefaultValue, replaces as Array<Replaces>)
  }} /> : checkReplace(text.value || returnDefaultValue, replaces as Array<Replaces>) : returnDefaultValue;
}