import Loading from 'assets/loading';
import AppLogo from 'assets/logo/appLogo';
import { defaultAutoCloseTimer } from 'components/statusBanner/types';
import Grid from 'components/uiKit/grid';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
interface Props {
  redirect?: string;
}
export default function IframeRedirect({
  redirect
}: Props) {
  const {
    query
  } = useRouter();
  const {
    reviewFe,
    origin
  } = query;
  function parseUrl(url: string): string {
    let nUrl = url.replace('&reviewFe=none', '');
    if (nUrl.includes('state=success')) {
      nUrl = `${nUrl}&autoClose=${defaultAutoCloseTimer}`;
    }
    return nUrl;
  }
  useEffect(() => {
    if (origin !== 'backOffice') {
      if (window.top && window.self !== window.top) {
        window.top.location.replace(parseUrl(redirect || window.self.location.href));
      } else if (redirect) {
        window.location.replace(parseUrl(redirect));
      } else if (reviewFe) {
        window.location.replace(parseUrl(window.location.href));
      }
    }
  }, []);
  return <Grid gap="1rem" padding={['ps-3', 'p-3']} className="scroll" data-sentry-element="Grid" data-sentry-component="IframeRedirect" data-sentry-source-file="index.tsx">
            <Grid horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                <AppLogo color="blue" width={150} height="100%" data-sentry-element="AppLogo" data-sentry-source-file="index.tsx" />
            </Grid>
            <Grid horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                <Loading data-sentry-element="Loading" data-sentry-source-file="index.tsx" />
            </Grid>
        </Grid>;
}