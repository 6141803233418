import Grid from 'components/uiKit/grid';
import { gridBaseProps } from 'components/uiKit/grid/types';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import styles from './styles.module.scss';
interface Props extends gridBaseProps {
  color?: 'blue' | 'white';
  children: React.ReactElement | React.ReactElement[];
}
export default function Card(props: Props) {
  const {
    color,
    children,
    className,
    ...gProps
  } = props;
  return <Grid data-color={color} className={twMerge(styles.wrapper, className)} {...gProps} data-sentry-element="Grid" data-sentry-component="Card" data-sentry-source-file="index.tsx">
      {children}
    </Grid>;
}